import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import OpenInNew from '@material-ui/icons/OpenInNew';
import { colors } from '../../../Layout';

type ClassNames = 'anchor' | 'icon';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  anchor: {
    color: colors.black,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  icon: {
    fontSize: '14px',
    marginLeft: '4px'
  },
});

interface Props {
  debtorId: string;
  invoiceNumber: string;
}

const path = '/Melin/Admin/EconomyCustomerAccountCard.aspx?handler=handleCustomerAccountCardForm';

enum InvoiceSystems {
  MMSYS1 = 'mmsys1',
  MMSYS2 = 'mmsys2'
}

type ComponentProps = Props & WithStyles<ClassNames>;

const getSystem = (debtorId: string) => {
  return debtorId.startsWith(InvoiceSystems.MMSYS1) ? InvoiceSystems.MMSYS1 : InvoiceSystems.MMSYS2; 
};

const getHostName = (debtorId: string) => {
  const hostname = window.location.hostname;
  const system = getSystem(debtorId);
  if (hostname.startsWith('conveneportal.dev') || hostname === 'localhost') {
    return system ===  InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_DEV : process.env.REACT_APP_MMSYS2_HOST_NAME_DEV;
  }

  if (hostname.startsWith('conveneportal.qa')) {
    return system === InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_QA : process.env.REACT_APP_MMSYS2_HOST_NAME_QA;
  }

  return system === InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_PROD : process.env.REACT_APP_MMSYS2_HOST_NAME_PROD;
};

const trimOffSystem = (debtorId: string) => {
  return debtorId.slice(debtorId.indexOf('-') + 1);
};

const ExternalLinkToInvoice: React.FunctionComponent<ComponentProps> = props => (
  <a
    href={`${getHostName(props.debtorId)}${path}&debitorId=${trimOffSystem(props.debtorId)}&debitorType=0&invoiceNumber=${props.invoiceNumber}`}
    rel="noreferrer"
    target="_blank"
    className={props.classes.anchor}
  >
    {props.invoiceNumber}
    <OpenInNew className={props.classes.icon} />
  </a>
);

export default withStyles(styles)(ExternalLinkToInvoice);