import React from 'react';
import { LoginType, MsalAuthProviderFactory } from 'react-aad-msal';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/AuthenticationStore';
import Typography from '@material-ui/core/Typography';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles: StyleRules<ClassNames> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 20,
  }
};

type ClassNames = 'container';

interface AuthProvider {
  login(): void;
  userInfoChangedCallback(user: any): void;
  isLoggedIn(): boolean;
  checkIfUserAuthenticated(): void;
  acquireTokens(idToken: string): void;
}

interface PropsFromRoute extends RouteComponentProps<{}> {
}

interface PropsFromDispatch {
  authenticated: typeof actionCreators.authenticated;
}

type ComponentProps = PropsFromRoute & PropsFromDispatch & WithStyles<ClassNames>;

class Login extends React.Component<ComponentProps> {
  constructor(props: any) {
    super(props);

    const providerFactory: MsalAuthProviderFactory = new MsalAuthProviderFactory({
      clientID: this.getClientId(),
      scopes: new Array(`${window.location.origin}${process.env.REACT_APP_AUTHORIZATION_SCOPES}`),
      authority: process.env.REACT_APP_AUTHORIZATION_AUTHORITY,
      type: LoginType.Redirect,
      persistLoginPastSession: true
    });

    this.provider = providerFactory.getAuthProvider() as AuthProvider;
    this.provider.userInfoChangedCallback = (payload: any) => {
      this.props.authenticated(payload);
      // Until state parameter is available we use local storage https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/262
      if (this.timer === null) {
        this.timer = setInterval(
          () => {
            this.provider.acquireTokens('not in use atm');
          },
          1 * 60 * 1000); // Refreshing token from cache unless it has expired and is within the offset of 5 mins.
        this.props.history.push('/');
      }
    };
  }

  private provider: AuthProvider;
  private timer: NodeJS.Timer | null = null;

  componentDidMount() {
    if (this.isAuthenticating() === false && this.provider.isLoggedIn() === false) {
      this.provider.login();
    }
  }

  public render() {
    return (
      <div className={this.props.classes.container}>
        <CircularProgress size={75} />
        <Typography>Holder på å logge deg inn...</Typography>
      </div>
    );
  }

  private isAuthenticating(): boolean {
    return window.location.hash !== '';
  }

  private getClientId(): string | undefined {
    const hostname = window.location.hostname;
    if (hostname.startsWith('conveneportal.dev') || hostname === 'localhost') {
      return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_DEV;
    }

    if (hostname.startsWith('conveneportal.qa')) {
      return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_QA;
    }

    return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_PROD;
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  authenticated: actionCreators.authenticated,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps))
  (withRouter(Login));