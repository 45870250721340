import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { DebtorIndex } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTOR = '@@debtorOverview/GET_DEBTOR',
  GET_DEBTOR_REQUEST = '@@debtorOverview/GET_DEBTOR_REQUEST',
  GET_DEBTOR_SUCCESS = '@@debtorOverview/GET_DEBTOR_SUCCESS',
  GET_DEBTOR_FAILURE = '@@debtorOverview/GET_DEBTOR_FAILURE'
}

export interface DebtorOverviewState {
  debtor: DebtorIndex | null;
  isLoading: boolean;
}

interface GetDebtorAction { type: ActionTypes.GET_DEBTOR; fetchConfig: FetchConfig; }
interface GetDebtorRequestAction { type: ActionTypes.GET_DEBTOR_REQUEST; id: string; }
interface GetDebtorSuccessAction { type: ActionTypes.GET_DEBTOR_SUCCESS; response: DebtorIndex | null; }
interface GetDebtorFailureAction { type: ActionTypes.GET_DEBTOR_FAILURE; response: string; }

export type KnownAction = GetDebtorAction | GetDebtorRequestAction | GetDebtorSuccessAction | GetDebtorFailureAction | AddNotificationAction;

export const actionCreators = {
  getDebtor: (id: string): AppThunkAction<KnownAction> => (dispatch) => {
    return dispatch({
      type: ActionTypes.GET_DEBTOR,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_REQUEST, id },
        path: `/api/Debtors/${id}`,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_SUCCESS, response: json });
        },
        failure: (error) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_FAILURE, response: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor data', variant: 'error' } });
        }
      }
    });
  }
};

export const initialState: DebtorOverviewState = { debtor: null, isLoading: false };

export const reducer: Reducer<DebtorOverviewState | undefined, KnownAction> = (state: DebtorOverviewState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_SUCCESS:
      return { ...state, debtor: action.response, isLoading: false };
    case ActionTypes.GET_DEBTOR_FAILURE:
      return { ...state, debtor: null, isLoading: false };
    default:
      return state;
  }
};