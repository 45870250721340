import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import DebtorSearchDialog from '../debtorSearch/containers/DebtorSearchDialog';
import DebtorSearchResults from '../debtorSearch/containers/DebtorSearchResults';
import customerCareWorkerPage from '../authentication/CustomerCareWorkerPage';
import SubmittedConversationsDrawer from '../submittedConversations/containers/SubmittedConversationsDrawer';

type ClassNames = 'searchDialog' | 'searchResults' | 'submittedConversations' | 'root';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  searchDialog: {
    gridColumnStart: '1',
    gridRowStart: '1',
  },
  searchResults: {
    gridColumnStart: '1',
    gridRowStart: '2',
  },
  submittedConversations: {
    gridColumnStart: '2',
    gridRowStart: '2',
    position: 'fixed',
    bottom: 0,
    right: 10,
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '95% auto',
    gridTemplateRows: '120px auto',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
});

type ComponentProps = WithStyles<ClassNames>;

const DebtorSearch: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.root}>
    <div className={props.classes.searchDialog}>
      <DebtorSearchDialog />
    </div>

    <div className={props.classes.searchResults}>
      <DebtorSearchResults />
    </div>

    <div className={props.classes.submittedConversations}>
      <SubmittedConversationsDrawer />
    </div>
  </div>
);

export default withStyles(styles)(customerCareWorkerPage(DebtorSearch));