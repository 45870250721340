import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AvailablePowerOfAttorney } from '../../../api/client';
import { ApplicationState } from '../../../store';

type ClassNames = 'formControlLabelDeleteIcon';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  formControlLabelDeleteIcon: {
    alignItems: 'center',
    display: 'inline-flex'
  },
});

interface InjectedProps {
  powerOfAttorney: AvailablePowerOfAttorney;
}

interface PropsFromDispatch {
  deactivatePowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.deactivatePowerOfAttorney;
}

interface PropsFromState {
  userProfile?: string;
}

interface State {
  open: boolean;
}

type ComponentProps = PropsFromDispatch & PropsFromState & InjectedProps & WithStyles<ClassNames>;

export class DeactivatePowerOfAttorneyDialog extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      open: false
    };
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleDeactivatePowerOfAttorney = () => {
    this.props.deactivatePowerOfAttorney(this.props.powerOfAttorney.debtorId || '', this.props.powerOfAttorney.id, this.props.userProfile || '');
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  public render() {
    return (
      <>
        <div className={this.props.classes.formControlLabelDeleteIcon}>
          <Tooltip title="Deaktiver fullmakt">
            <IconButton aria-label="Delete" onClick={this.handleClickOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Deaktiver fullmakt</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du helt sikker på at du vil deaktivere fullmakten?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Avbryt
            </Button>
            <Button onClick={this.handleDeactivatePowerOfAttorney} color="primary" autoFocus={true}>
              Deaktiver fullmakten
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  deactivatePowerOfAttorney: PowerOfAttorneyStoreActionCreators.deactivatePowerOfAttorney,
};

const mapStateToProps = ({ authentication, notifications }: ApplicationState): PropsFromState => ({
  userProfile: authentication.userProfile,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DeactivatePowerOfAttorneyDialog);