import { Action, Middleware, MiddlewareAPI, Dispatch } from 'redux';

export interface FetchConfig {
  init?: any;
  path: string;
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT';
  headers?: any;
  body?: any;
  success(json: any);
  failure(error: string);
}

const fetchMiddleware: Middleware = <S = any, D extends Dispatch = Dispatch>(store: MiddlewareAPI<D, S>) =>
  (next: Dispatch) => <A extends Action>(action: A & { fetchConfig: FetchConfig }): A | void => {

    if (!action || !action.fetchConfig) {
      return next(action);
    }

    const config = action.fetchConfig;
    store.dispatch(config.init);

    const path = config.path || '/';
    const method = config.method || 'GET';
    const headers = config.headers || { Accept: 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'application/json' };
    const body = config.body;
    const successHandler = config.success;
    const failureHandler = config.failure;

    fetch(path, {
      method: method,
      headers: headers,
      body: JSON.stringify(body)
    })
      .then((response: Response) => response.status === 204 ? null : response.json())
      .then(json => successHandler(json))
      .catch(error => failureHandler(error));
  };

export default fetchMiddleware;