import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import AccountNumber from '../../common/AccountNumber';

type ClassNames = 'container' | 'kid' | 'accountNumber';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '60%',
    margin: '0 auto',
    backgroundColor: '#ecf1f4',
    marginTop: 16,
    marginBottom: 38,
    paddingTop: 10,
    paddingBottom: 10,
  },
  kid: {
    paddingLeft: 20,
  },
  accountNumber: {
    paddingRight: 20,
  }
});

interface Props {
  kid?: string | undefined;
  accountNumber?: string | undefined;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorPaymentInformation: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.container}>
    <Typography className={props.classes.kid}>KID-nummer: {props.kid}</Typography>
    <AccountNumber className={props.classes.accountNumber} accountNumber={props.accountNumber} />
  </div>
);

export default withStyles(styles)(DebtorPaymentInformation);