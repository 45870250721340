import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { colors } from '../../Layout';
import { Notification } from '../../store/NotificationsStore';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

type ClassName = 'error' | 'icon' | 'iconVariant' | 'info' | 'margin' | 'message' | 'success' | 'warning';

const styles: StyleRulesCallback<ClassName> = theme => ({
  error: {
    backgroundColor: colors.red,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  info: {
    backgroundColor: colors.blue,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    backgroundColor: colors.green,
  },
  warning: {
    backgroundColor: colors.amber,
  },
});

interface Props {
  notification: Notification;
  onClose: (event: any) => void;
}

type ComponentProps = Props & WithStyles<ClassName>;

const NotificationSnackbarContent: React.FunctionComponent<ComponentProps> = props => {
  const Icon = variantIcon[props.notification.variant];

  return (
    <SnackbarContent
      className={`${props.classes[props.notification.variant]} ${props.classes.margin}`}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={props.classes.message}>
          <Icon className={`${props.classes.icon} ${props.classes.iconVariant}`} />
          {props.notification.message}
        </span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={props.onClose}
        >
          <CloseIcon className={props.classes.icon} />
        </IconButton>,
      ]}
    />
  );
};

export default withStyles(styles)(NotificationSnackbarContent);