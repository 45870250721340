import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import { colors } from '../../../Layout';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

type ClassNames = 'root' | 'searchInput';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  root: {
    padding: 0,
    margin: '20px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 88,
    width: 780,
    backgroundColor: colors.lightGray,
  },
  searchInput: {
    width: 700,
    margin: 'auto'
  },
});

interface PropsFromState {
}

interface PropsFromDispatch {
  searchDebtors: typeof DebtorSearchStoreActionCreators.searchDebtors;
}

interface PropsFromRoute extends RouteComponentProps<{}> {
}

interface State {
  criteria: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & PropsFromRoute & State & WithStyles<ClassNames>;

export class DebtorSearchDialog extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { criteria: '' };
  }

  componentDidMount() {
    const searchCriteria = this.parseSearchCriteriaFromQueryString();
    if (searchCriteria !== null) {
      this.setState({ criteria: searchCriteria }, () => this.props.searchDebtors(this.state.criteria));
    }
  }

  parseSearchCriteriaFromQueryString(): string | null {
    if (this.props.location.search !== undefined) {
      const parsedQueryString = queryString.parse(window.location!.search.toLowerCase());
      if (parsedQueryString.searchcriteria !== null && parsedQueryString.searchcriteria !== undefined) {
        if (typeof (parsedQueryString.searchcriteria) === 'string') {
          return parsedQueryString.searchcriteria;
        }
        else {
          return parsedQueryString.searchcriteria.join(' ');
        }
      }
    }
    return null;
  }

  handleCriteriaChange = event => {
    this.setState({ criteria: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.searchDebtors(this.state.criteria);
  }

  public render() {
    return (
      <Paper className={this.props.classes.root}>
        <form onSubmit={this.handleSubmit} >
          <TextField
            autoFocus={true}
            className={this.props.classes.searchInput}
            placeholder="Søk etter fødselsnr, fakturanr, saksnr, navn, tlf, kundenr, ordrenr eller KID (alt + s for fokus) "
            type="search"
            value={this.state.criteria}
            onChange={this.handleCriteriaChange}
            inputProps={{ accessKey: 's', 'data-id': 'searchCriteria' }}
          />
          <IconButton aria-label="Delete" type="submit" data-id="searchSubmit">
            <Search />
          </IconButton>
        </form>
      </Paper>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  searchDebtors: DebtorSearchStoreActionCreators.searchDebtors,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(withRouter(DebtorSearchDialog));
