import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { ResultOfDebtorIndex } from '../../api/client';
import { actionCreators as DebtorNationalIdentificationNumberSearchActionCreators } from '../../store/DebtorNationalIdentificationNumberSearchStore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import { createDebtorIdsQueryString } from '../Utils';

type ClassNames = 'progress';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  progress: {
    width: '75px',
    margin: '0 auto',
  }
});

interface PropsFromState {
  isSearching: boolean;
  searchComplete: boolean;
  selectedResult?: ResultOfDebtorIndex | null;
  nationalIdentificationNumberResults?: ResultOfDebtorIndex[];
  validSearch?: boolean | null;
}

interface PropsFromDispatch {
  searchDebtorsByNationalIdentificationNumber: typeof DebtorNationalIdentificationNumberSearchActionCreators.searchDebtorsByNationalIdentificationNumber;
  setValidSearch: typeof DebtorNationalIdentificationNumberSearchActionCreators.setValidSearch;
}

type ComponentProps = PropsFromState & PropsFromDispatch & WithStyles<ClassNames>;

class DebtorSearchResultFindRelatedDebtorIds extends React.Component<ComponentProps> {

  componentDidMount() {
    if (
      this.props.selectedResult != null &&
      this.props.selectedResult.document != null &&
      this.props.selectedResult.document.nationalIdentityNumber != null &&
      this.props.selectedResult.document.nationalIdentityNumber.length === 11 &&
      this.props.selectedResult.highlights && 
      (Object.keys(this.props.selectedResult.highlights)).indexOf('nationalIdentityNumber') === -1
    ) {
      this.props.setValidSearch(true);
      this.props.searchDebtorsByNationalIdentificationNumber(this.props.selectedResult.document.nationalIdentityNumber);
    } else {
      this.props.setValidSearch(false);
    }
  }

  public render() {
    if (
      (
        this.props.validSearch === false ||
        (this.props.searchComplete === true && !this.props.nationalIdentificationNumberResults)
      ) || (
        this.props.validSearch === true && 
        this.props.searchComplete === true && 
        (this.props.nationalIdentificationNumberResults == null || this.props.nationalIdentificationNumberResults[0] == null)
      )
    ) {
      const debtorUrl = `/debtor?${createDebtorIdsQueryString(this.props.selectedResult!.document!.relatedDebtorIds)}`;
      return (
        <Redirect to={debtorUrl} />
      );
    }
    if (
      this.props.validSearch === true &&
      this.props.searchComplete === true &&
      this.props.nationalIdentificationNumberResults != null &&
      this.props.nationalIdentificationNumberResults[0] != null &&
      this.props.nationalIdentificationNumberResults[0].document != null &&
      this.props.nationalIdentificationNumberResults[0].document.relatedDebtorIds != null && 
      this.props.nationalIdentificationNumberResults[0].document.relatedDebtorIds.length > 0
    ) {
      const debtorUrl = `/debtor?${createDebtorIdsQueryString(this.props.nationalIdentificationNumberResults![0].document!.relatedDebtorIds)}`;
      return (
        <Redirect to={debtorUrl} />
      );
    }
    return (
      <div>
        {!this.props.searchComplete &&
          <div className={this.props.classes.progress}>
            <CircularProgress size={75} />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ debtorSearch, debtorNationalIdentificationNumberSearch }: ApplicationState): PropsFromState => ({
  isSearching: debtorNationalIdentificationNumberSearch.isSearching,
  selectedResult: debtorSearch.selectedResult,
  searchComplete: debtorNationalIdentificationNumberSearch.searchComplete,
  nationalIdentificationNumberResults: debtorNationalIdentificationNumberSearch.results ? debtorNationalIdentificationNumberSearch.results.results : [],
  validSearch: debtorNationalIdentificationNumberSearch.validSearch
});

const mapDispatchToProps: PropsFromDispatch = {
  searchDebtorsByNationalIdentificationNumber:  DebtorNationalIdentificationNumberSearchActionCreators.searchDebtorsByNationalIdentificationNumber,
  setValidSearch: DebtorNationalIdentificationNumberSearchActionCreators.setValidSearch
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DebtorSearchResultFindRelatedDebtorIds);