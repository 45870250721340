import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import AdministratorPage from '../../components/authentication/AdministratorPage';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'text';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  text: {
    color: `${theme.palette.secondary.dark}`
  }
});
type ComponentProps = WithStyles<ClassNames>;

const Administration: React.FunctionComponent<ComponentProps> = props => (
  <Typography className={props.classes.text}>Administration page</Typography>
);

export default withStyles(styles)(AdministratorPage(Administration));