import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorIndex } from '../../../api/client';
import { formatNationalIdentityNumber, formatPhoneNumber } from '../../Utils';
import { DebtOverview } from '../../debtOverview/DebtOverview';
import currencyFormatter from 'currency-formatter';

type ClassNames = 'root' | 'rootNoData' | 'debtorSubSection' | 'personName' | 'noOutstandingDebt' | 'outstandingDebt';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  root: {
    padding: '28px 20px 20px 20px'
  },
  rootNoData: {
    extend: 'root',
    textAlign: 'center',
  },
  debtorSubSection: {
    marginBottom: 10
  },
  personName: {
    float: 'left',
    marginRight: 10,
    fontWeight: 'bold'
  },
  noOutstandingDebt: {
    color: theme.palette.secondary.dark
  },
  outstandingDebt: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  }
});

interface Props {
  debtor: DebtorIndex | null;
  outstanding: number;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const Debtor: React.FunctionComponent<ComponentProps> = props => (
  props.debtor
    ? (
      <div className={props.classes.root}>
        <div className={props.classes.debtorSubSection}>
          <Typography variant="h6" className={props.classes.personName}>{props.debtor.name}</Typography>
          <Typography variant="subtitle1" >{formatNationalIdentityNumber(props.debtor.nationalIdentityNumber) || props.debtor.dateOfBirth}</Typography>
          <Typography variant="subtitle1" style={{ float: 'right' }}>
            Totalt utestående: <span className={(props.outstanding === 0 ? props.classes.noOutstandingDebt : props.classes.outstandingDebt)}>
              {currencyFormatter.format(props.outstanding, { code: 'NOK' })}</span>
          </Typography>
        </div>
        <div className={props.classes.debtorSubSection}>
          <Typography>{props.debtor.street}</Typography>
          <Typography>{props.debtor.postCode} {props.debtor.postArea}</Typography>
          <Typography>{props.debtor.country}</Typography>
        </div>
        <div className={props.classes.debtorSubSection}>
          {props.debtor.phoneNumbers && [...new Set(props.debtor.phoneNumbers)].map((item, i) => <Typography key={i}>{formatPhoneNumber(item)}</Typography>)}
        </div>
        <div className={props.classes.debtorSubSection}>
          <Typography>{props.debtor.email}</Typography>
        </div>
        <DebtOverview />
      </div>
    )
    : (
      <div className={props.classes.rootNoData}>
        <Typography variant="h5">Kunne ikke hente debitor data</Typography>
        <DebtOverview />
      </div>
    )

);

export default withStyles(styles)(Debtor);