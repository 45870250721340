import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

type ClassNames = 'container' | 'highlight' | 'label';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    marginTop: 10,
    display: 'flex',
  },
  highlight: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.dark,
    borderRadius: 25,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    '& em': {
      backgroundColor: theme.palette.primary.light,
    },
    marginRight: 10,
  },
  label: {
    paddingTop: 3,
    fontWeight: 'bold',
    marginRight: 6
  }
});

interface Props {
  highlights: any;
}

type ComponentProps = Props & WithStyles<ClassNames>;

function translateField(value: string): string {
  switch (value) {
    case 'name': return 'Navn';
    case 'dateOfBirth': return 'Fødselsdato';
    case 'debtorIds': return 'Kundenummer';
    case 'nationalIdentityNumber': return 'Fødselsnummer';
    case 'phoneNumbers': return 'Telefonnummer';
    case 'invoiceNumbers': return 'Fakturanummer';
    case 'caseNumbers': return 'Saksnummer';
    case 'orderNumbers': return 'Ordrenummer';
    case 'kidNumbers': return 'KID';
    default:
      return value;
  }
}

const DebtorSearchResultReason: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.container}>
    <Typography className={props.classes.label}>Treff på:</Typography>
    {props.highlights && Object.keys(props.highlights).map((item, i) => 
        <Typography className={props.classes.highlight} key={i}>
          {translateField(item)}:{' '}{ReactHtmlParser(props.highlights[item])}
        </Typography>
    )}
  </div>
);

export default withStyles(styles)(DebtorSearchResultReason);