import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { SelectedDebtorClaim, SendPaymentInformationConversationAction } from '../../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { hasSelectedDebtorClaims } from '../../../../store/Selectors';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import Button from '../../../../components/common/Button';
import { StyleRulesCallback, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input';
import { parsePhoneNumber } from 'awesome-phonenumber';

type ClassNames = 'contentContainer' | 'buttonsContainer';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  mobileNumbers: string[];
  emailAddresses: string[];
  additionalText: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class SendPaymentInformation extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      mobileNumbers: [],
      emailAddresses: [],
      additionalText: ''
    };
  }

  isValidMobileNumbers = () => {
    return this.state.mobileNumbers.length > 0;
  }

  isValidEmailAddresses = () => {
    return this.state.emailAddresses.length > 0;
  }

  isValid = () => {
    return (this.isValidMobileNumbers() || this.isValidEmailAddresses()) && this.props.hasSelectedDebtorClaims;
  }

  handleChangeOnFreeText = event => {
    this.setState({ additionalText: event.target.value });
  }

  handleOnMobileNumberBeforeAdd = (value: string): boolean => {
    if (value.startsWith('+')) {
      return true;
    }
    const phoneNumber = parsePhoneNumber(value, { regionCode: 'NO' });
    return phoneNumber.valid && phoneNumber.typeIsMobile;
  }

  handleOnMobileNumberAdded = (value: string) => {
    let mobileNumber = '';
    if (value.startsWith('+')) {
      mobileNumber = value;
    }
    else {
      mobileNumber = parsePhoneNumber(value, { regionCode: 'NO' }).number?.international ?? '';
    }
    this.setState({
      mobileNumbers: [...this.state.mobileNumbers, mobileNumber]
    });
  }

  handleOnMobileNumberDeleted = (value: string) => {
    this.setState({
      mobileNumbers: this.state.mobileNumbers.filter((c) => c !== value)
    });
  }

  handleOnEmailAddressAdded = (value: string) => {
    this.setState({
      emailAddresses: [...this.state.emailAddresses, value]
    });
  }

  handleOnEmailAddressDeleted = (value: string) => {
    this.setState({
      emailAddresses: this.state.emailAddresses.filter((c) => c !== value)
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const action: SendPaymentInformationConversationAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      mobilePhoneNumbers: this.state.mobileNumbers,
      emailAddresses: this.state.emailAddresses,
      additionalText: this.state.additionalText,
      discriminator: 'SendPaymentInformationConversationAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <div>
        <div className={this.props.classes.contentContainer}>
          <Typography>Send betalingsinformasjon</Typography>
          <ChipInput
            {...this.props}
            value={this.state.mobileNumbers}
            onAdd={this.handleOnMobileNumberAdded}
            onDelete={this.handleOnMobileNumberDeleted}
            onBeforeAdd={this.handleOnMobileNumberBeforeAdd}
            label="Mobilnummer"
            error={!this.isValidMobileNumbers()}
            margin="normal"
            newChipKeyCodes={[9, 13]}
          />
          <ChipInput
            {...this.props}
            value={this.state.emailAddresses}
            onAdd={this.handleOnEmailAddressAdded}
            onDelete={this.handleOnEmailAddressDeleted}
            label="E-postadresser"
            error={!this.isValidEmailAddresses()}
            margin="normal"
            newChipKeyCodes={[9, 13]}
          />
          <TextField
            label="Tilleggsinformasjon til e-post"
            margin="normal"
            multiline={true}
            value={this.state.additionalText}
            onChange={this.handleChangeOnFreeText}
          />
        </div>
        <div className={this.props.classes.buttonsContainer}>
          <Button onClick={() => this.props.jumpToStep(Step.Start)}>Avbryt</Button>
          <Button id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">OK</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(debtorClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SendPaymentInformation);