import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import { hasSelectedDebtorClaims } from '../../../store/Selectors';
import { Step } from './ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ConversationStart from './ConversationStart';
import OtherReason from './steps/OtherReason';
import ConversationInterrupted from './steps/ConversationInterrupted';
import ConversationSummary from '../containers/ConversationSummary';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentAgreement from './steps/PaymentAgreement';
import PaymentInformation from './steps/PaymentInformation';
import SendPaymentInformation from './steps/SendPaymentInformation';
import HasPaid from './steps/HasPaid';
import PaymentNotRegistered from './steps/PaymentNotRegistered';
import Objection from './steps/Objection';
import Credit from './steps/Credit';

type ClassNames = 'container' | 'header' | 'progress';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: 442,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    padding: 27,
    minWidth: 440
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    width: '100%',
  },
  progress: {
    margin: '0 auto',
    display: 'block',
    marginTop: 30,
  }
});

interface PropsFromState {
  step: number;
  isSummary: boolean;
  isSubmitting: boolean;
  hasSelectedDebtorClaims: boolean;
  hasConversationSupervisorActions: boolean;
}

interface PropsFromDispatch {
  setConversationSupervisorStep: typeof actionCreators.setSupervisorStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & WithStyles<ClassNames>;

class ConversationSupervisor extends React.Component<ComponentProps> {
  public render() {
    const stepComponents = [
      { name: Step.Start.toString(), component: <ConversationStart hasConversationSupervisorActions={this.props.hasConversationSupervisorActions} /> },
      { name: Step.OtherReason.toString(), component: <OtherReason /> },
      { name: Step.ConversationInterrupted.toString(), component: <ConversationInterrupted /> },
      { name: Step.Summary.toString(), component: <ConversationSummary /> },
      { name: Step.PaymentAgreement.toString(), component: <PaymentAgreement hasSelectedDebtorClaims={this.props.hasSelectedDebtorClaims} /> },
      { name: Step.PaymentInformation.toString(), component: <PaymentInformation hasSelectedDebtorClaims={this.props.hasSelectedDebtorClaims} /> },
      { name: Step.SendPaymentInformation.toString(), component: <SendPaymentInformation /> },
      { name: Step.HasPaid.toString(), component: <HasPaid /> },
      { name: Step.PaymentNotRegistered.toString(), component: <PaymentNotRegistered /> },
      { name: Step.Objection.toString(), component: <Objection /> },
      { name: Step.Credit.toString(), component: <Credit /> },
    ];

    return (
      <div className={this.props.classes.container}>
        <Typography className={this.props.classes.header}>Samtaleveileder{this.props.isSummary ? ' oppsummering' : ''}</Typography>
        {!this.props.isSubmitting &&
          <StepZilla
            steps={stepComponents}
            showNavigation={false}
            showSteps={false}
            stepsNavigation={false}
            dontValidate={true}
            startAtStep={this.props.step}
            preventEnterSubmission={true}
            onStepChange={step => this.props.setConversationSupervisorStep(step)}
          />
        }
        {this.props.isSubmitting &&
          <CircularProgress size={75} className={this.props.classes.progress} />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ conversationSupervisor, debtorClaims }: ApplicationState): PropsFromState => ({
  step: conversationSupervisor.step,
  isSummary: conversationSupervisor.step === Step.Summary,
  isSubmitting: conversationSupervisor.isSubmitting,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(debtorClaims),
  hasConversationSupervisorActions: conversationSupervisor.conversation.actions!.length > 0,
});

const mapDispatchToProps: PropsFromDispatch = {
  setConversationSupervisorStep: actionCreators.setSupervisorStep,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ConversationSupervisor);