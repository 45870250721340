import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { ResponseOfDebtorIndex, ResultOfDebtorIndex } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTORS = '@@debtorSearch/GET_DEBTORS',
  GET_DEBTORS_REQUEST = '@@debtorSearch/GET_DEBTORS_REQUEST',
  GET_DEBTORS_SUCCESS = '@@debtorSearch/GET_DEBTORS_SUCCESS',
  GET_DEBTORS_FAILURE = '@@debtorSearch/GET_DEBTORS_FAILURE',
  CLEAR_SEARCH_RESULTS = '@@debtorSearch/CLEAR_SEARCH_RESULTS',
  SELECT_DEBTOR = '@@debtorSearch/SELECT_DEBTOR'
}

export interface DebtorSearchState {
  criteria: string;
  isSearching: boolean;
  results: ResponseOfDebtorIndex | null;
  selectedResult: ResultOfDebtorIndex | null;
}

interface GetDebtorsAction { type: ActionTypes.GET_DEBTORS; fetchConfig: FetchConfig; }
interface GetDebtorsRequestAction { type: ActionTypes.GET_DEBTORS_REQUEST; criteria: string; }
interface GetDebtorsSuccessAction { type: ActionTypes.GET_DEBTORS_SUCCESS; response: ResponseOfDebtorIndex | null; }
interface GetDebtorsFailureAction { type: ActionTypes.GET_DEBTORS_FAILURE; response: string; }
export interface ClearSearchResultsAction { type: ActionTypes.CLEAR_SEARCH_RESULTS; }
export interface SelectDebtorAction { type: ActionTypes.SELECT_DEBTOR; debtor: ResultOfDebtorIndex; }

export type KnownAction = GetDebtorsAction
  | GetDebtorsRequestAction
  | GetDebtorsSuccessAction
  | GetDebtorsFailureAction
  | ClearSearchResultsAction
  | SelectDebtorAction
  | AddNotificationAction;

export const actionCreators = {
  searchDebtors: (criteria: string): AppThunkAction<KnownAction> => dispatch => {
    return dispatch({
      type: ActionTypes.GET_DEBTORS,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTORS_REQUEST, criteria },
        path: `/api/Debtors?criteria=${criteria}`,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBTORS_SUCCESS, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTORS_FAILURE, response: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke utføre søket', variant: 'error' } });
        }
      }
    });
  },
  selectDebtor: (debtor: ResultOfDebtorIndex): AppThunkAction<KnownAction> => dispatch => {
    return dispatch({ type: ActionTypes.SELECT_DEBTOR, debtor });
  },
  clearSearchResults: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_SEARCH_RESULTS });
  }
};

export const initialState: DebtorSearchState = { criteria: '', isSearching: false, results: null, selectedResult: null };

export const reducer: Reducer<DebtorSearchState | undefined, KnownAction> = (state: DebtorSearchState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTORS_REQUEST:
      return { ...state, criteria: action.criteria, isSearching: true };
    case ActionTypes.GET_DEBTORS_SUCCESS:
      return { ...state, isSearching: false, results: action.response };
    case ActionTypes.GET_DEBTORS_FAILURE:
      return { ...state, isSearching: false };
    case ActionTypes.SELECT_DEBTOR:
      return { ...state, selectedResult: action.debtor };
    case ActionTypes.CLEAR_SEARCH_RESULTS:
      return initialState;
    default:
      return state;
  }
};