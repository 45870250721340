import React from 'react';
import { Svg, G, Path, Rect } from '@react-pdf/renderer';

export const ConveneLogo = ({ style }: { style?: Record<string, string> }) => (
  <Svg viewBox="0 0 267 56.65" style={style}>
    <G fill="#193D65">
      <Path d="M43.17,35.46a7,7,0,0,1-.89,3.19,11,11,0,0,1-2.72,3.23A14.11,14.11,0,0,1,35,44.35a19.37,19.37,0,0,1-6.26.94q-7.39,0-11.55-4.32T13,29.41a19,19,0,0,1,1.91-8.69,13.65,13.65,0,0,1,5.49-5.84A17.08,17.08,0,0,1,29,12.82a16.94,16.94,0,0,1,5.69.91,14.63,14.63,0,0,1,4.39,2.34,11.08,11.08,0,0,1,2.75,3.05,6.11,6.11,0,0,1,.95,3,3.19,3.19,0,0,1-1.07,2.43,3.66,3.66,0,0,1-2.59,1,2.62,2.62,0,0,1-1.65-.51A7.86,7.86,0,0,1,36,23.41a12.21,12.21,0,0,0-3.06-3.33A6.87,6.87,0,0,0,28.87,19a6.89,6.89,0,0,0-5.71,2.77A12,12,0,0,0,21,29.29a14.65,14.65,0,0,0,.55,4.14,9.58,9.58,0,0,0,1.61,3.21,6.92,6.92,0,0,0,2.55,2,7.66,7.66,0,0,0,3.27.69,7.38,7.38,0,0,0,4.11-1.11,9.7,9.7,0,0,0,3-3.4,7.89,7.89,0,0,1,1.58-2.1A3,3,0,0,1,39.78,32a3.07,3.07,0,0,1,2.42,1.11A3.53,3.53,0,0,1,43.17,35.46Z" />
      <Path d="M93.67,17.28v1a13.75,13.75,0,0,1,4.59-4.06A12.15,12.15,0,0,1,104,12.9a11.47,11.47,0,0,1,5.63,1.37,8.67,8.67,0,0,1,3.7,3.88,9.2,9.2,0,0,1,1,3.15,33.51,33.51,0,0,1,.23,4.31V40.47a5.29,5.29,0,0,1-1.11,3.65,3.64,3.64,0,0,1-2.87,1.24,3.73,3.73,0,0,1-2.92-1.26,5.31,5.31,0,0,1-1.12-3.63V27.2a13.32,13.32,0,0,0-1.11-6.08c-.73-1.4-2.2-2.11-4.39-2.11a6.54,6.54,0,0,0-3.92,1.28,7.39,7.39,0,0,0-2.59,3.52A25.31,25.31,0,0,0,94,30.52V40.57a5,5,0,0,1-1.09,3.52,3.66,3.66,0,0,1-2.81,1.18,3.51,3.51,0,0,1-2.75-1.22,5.07,5.07,0,0,1-1.08-3.48V17.4a5,5,0,0,1,1-3.39,3.45,3.45,0,0,1,2.71-1.12,3.71,3.71,0,0,1,1.89.5,3.54,3.54,0,0,1,1.35,1.48A5.2,5.2,0,0,1,93.67,17.28Z" />
      <Path d="M127.34,17.85,133.81,36l7-18.93a11.21,11.21,0,0,1,1.61-3.23,2.93,2.93,0,0,1,2.39-.95,3.61,3.61,0,0,1,2.6,1,3.18,3.18,0,0,1,1.06,2.36,4.62,4.62,0,0,1-.19,1.23,11.34,11.34,0,0,1-.44,1.32c-.16.41-.35.88-.57,1.4l-7.66,19c-.21.55-.49,1.24-.83,2.08a11.93,11.93,0,0,1-1.14,2.15,4.57,4.57,0,0,1-1.55,1.39,4.72,4.72,0,0,1-2.24.49,4.34,4.34,0,0,1-2.71-.77,5.22,5.22,0,0,1-1.49-1.7c-.32-.61-.86-1.83-1.62-3.64l-7.61-18.8q-.25-.7-.54-1.41a13.29,13.29,0,0,1-.47-1.43,5.51,5.51,0,0,1-.19-1.26,3,3,0,0,1,.5-1.62,3.81,3.81,0,0,1,1.38-1.32,3.68,3.68,0,0,1,1.9-.51A3,3,0,0,1,125.69,14,17.85,17.85,0,0,1,127.34,17.85Z" />
      <Path d="M175.66,31H160a10.6,10.6,0,0,0,1.1,4.79A7.37,7.37,0,0,0,167.88,40a9.72,9.72,0,0,0,2.62-.34,8.46,8.46,0,0,0,2.3-1,17,17,0,0,0,2-1.54c.63-.54,1.44-1.29,2.43-2.22a2.64,2.64,0,0,1,1.76-.53,2.89,2.89,0,0,1,2,.67,2.42,2.42,0,0,1,.77,1.91,5.28,5.28,0,0,1-.85,2.53,10.24,10.24,0,0,1-2.56,2.78,14.82,14.82,0,0,1-4.3,2.2,18.39,18.39,0,0,1-5.95.88q-7.69,0-12-4.39T151.88,29a19.58,19.58,0,0,1,1.06-6.56A14.46,14.46,0,0,1,156,17.2a13.29,13.29,0,0,1,5-3.32,17.84,17.84,0,0,1,6.55-1.15,15.59,15.59,0,0,1,8,2,13.13,13.13,0,0,1,5,5.1,13.47,13.47,0,0,1,1.66,6.38c0,2-.57,3.31-1.72,3.91A10.83,10.83,0,0,1,175.66,31ZM160,26.4h14.48c-.2-2.73-.93-4.77-2.21-6.13a6.61,6.61,0,0,0-5-2,6.39,6.39,0,0,0-4.9,2.06A10.31,10.31,0,0,0,160,26.4Z" />
      <Path d="M196.16,17.28v1a14.14,14.14,0,0,1,4.66-4.12,12.34,12.34,0,0,1,5.8-1.32,11.69,11.69,0,0,1,5.71,1.39,8.9,8.9,0,0,1,3.75,3.93,9.25,9.25,0,0,1,1,3.2,32.42,32.42,0,0,1,.24,4.37V40.47a5.29,5.29,0,0,1-1.11,3.65,3.64,3.64,0,0,1-2.87,1.24,3.73,3.73,0,0,1-2.92-1.26,5.31,5.31,0,0,1-1.12-3.63V27.2a13.47,13.47,0,0,0-1.1-6.08c-.74-1.4-2.21-2.11-4.4-2.11a6.54,6.54,0,0,0-3.92,1.28,7.45,7.45,0,0,0-2.59,3.52,25.31,25.31,0,0,0-.59,6.71v10a5.17,5.17,0,0,1-1.13,3.65,3.78,3.78,0,0,1-2.92,1.22,3.65,3.65,0,0,1-2.85-1.26,5.29,5.29,0,0,1-1.11-3.61V17.4a5,5,0,0,1,1-3.39,3.44,3.44,0,0,1,2.7-1.12,3.66,3.66,0,0,1,1.89.5,3.54,3.54,0,0,1,1.35,1.48A5.2,5.2,0,0,1,196.16,17.28Z" />
      <Path d="M247.43,31H231.81a10.73,10.73,0,0,0,1.1,4.79A7.37,7.37,0,0,0,239.65,40a9.72,9.72,0,0,0,2.62-.34,8.33,8.33,0,0,0,2.3-1A18,18,0,0,0,246.62,37c.62-.54,1.43-1.29,2.42-2.22a2.64,2.64,0,0,1,1.76-.53,2.94,2.94,0,0,1,2,.67,2.45,2.45,0,0,1,.76,1.91,5.28,5.28,0,0,1-.85,2.53,10.24,10.24,0,0,1-2.56,2.78,14.56,14.56,0,0,1-4.3,2.2,18.39,18.39,0,0,1-6,.88q-7.69,0-12-4.39T223.65,29a19.83,19.83,0,0,1,1.06-6.56,14.46,14.46,0,0,1,3.07-5.2,13.29,13.29,0,0,1,5-3.32,17.84,17.84,0,0,1,6.55-1.15,15.59,15.59,0,0,1,8,2,13,13,0,0,1,5,5.1A13.48,13.48,0,0,1,254,26.18c0,2-.58,3.31-1.73,3.91A10.83,10.83,0,0,1,247.43,31ZM231.81,26.4h14.48a9.73,9.73,0,0,0-2.2-6.13,6.64,6.64,0,0,0-5.05-2,6.41,6.41,0,0,0-4.9,2.06A10.31,10.31,0,0,0,231.81,26.4Z" />
    </G>
    <G fill="#19B4B8">
      <Rect x="47.68" y="27.93" width="2.43" height="2.43" rx="1.21" />
      <Rect x="49.58" y="34.99" width="3.15" height="2.58" rx="1.23" />
      <Rect x="55.02" y="40.71" width="4.01" height="2.58" rx="1.25" />
      <Path d="M80.5,29.14v.08A16.36,16.36,0,0,1,64.76,45.5H64.7a14,14,0,0,1-2.43-.11c-1.16-.14-1.24-.46-1.24-1.16V44a1.27,1.27,0,0,1,1.27-1.27h2.06A13.57,13.57,0,0,0,77.63,29.16v-.08A13.55,13.55,0,0,0,64.36,15.52H62.3A1.27,1.27,0,0,1,61,14.25v-.12c0-.7.08-1,1.24-1.16a14,14,0,0,1,2.43-.11h.06A16.36,16.36,0,0,1,80.5,29.14Z" />
      <Rect x="49.58" y="20.67" width="3.15" height="2.58" rx="1.23" />
      <Rect x="55.02" y="14.95" width="4.01" height="2.58" rx="1.25" />
      <Rect x="52.46" y="28.28" width="1.72" height="1.72" rx="0.86" />
      <Rect x="53.87" y="33.56" width="2.29" height="1.72" rx="0.86" />
      <Rect x="57.59" y="37.28" width="2.86" height="1.72" rx="0.86" />
      <Path d="M75.63,29.17v.06A11.56,11.56,0,0,1,64.52,40.76h0a10.1,10.1,0,0,1-1.72-.07c-.82-.1-.87-.33-.87-.83v0a.9.9,0,0,1,.9-.9H64.3a9.76,9.76,0,0,0,9.61-9.71v-.06a9.76,9.76,0,0,0-9.61-9.7H62.79a.9.9,0,0,1-.9-.9v-.09c0-.49,0-.72.87-.82a11.55,11.55,0,0,1,1.72-.08h0A11.56,11.56,0,0,1,75.63,29.17Z" />
      <Rect x="53.87" y="23.25" width="2.29" height="1.72" rx="0.86" />
      <Rect x="57.59" y="19.24" width="2.86" height="1.72" rx="0.86" />
      <Rect x="56.47" y="28.58" width="1.12" height="1.12" rx="0.56" />
      <Rect x="57.31" y="31.84" width="1.43" height="1.15" rx="0.57" />
      <Rect x="59.88" y="34.42" width="2" height="1.15" rx="0.57" />
      <Path d="M71.62,29.28v0a7.52,7.52,0,0,1-7.17,7.54h0a5.76,5.76,0,0,1-1.11,0c-.53-.06-.56-.21-.56-.54v-.12a.58.58,0,0,1,.59-.59h1a6.3,6.3,0,0,0,6.18-6.28v0A6.3,6.3,0,0,0,64.3,23h-1a.58.58,0,0,1-.59-.59v-.05c0-.33,0-.47.56-.54a6.71,6.71,0,0,1,1.11-.05h0A7.52,7.52,0,0,1,71.62,29.28Z" />
      <Rect x="57.31" y="25.25" width="1.43" height="1.15" rx="0.57" />
      <Rect x="59.88" y="22.68" width="2" height="1.15" rx="0.57" />
    </G>
  </Svg>
);