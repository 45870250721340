import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'text';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  text: {
    color: `${theme.palette.secondary.main}`
  }
});

const ApplicationVersion: React.FunctionComponent<WithStyles<ClassNames>> = props => (
      <Typography className={props.classes.text}>v{process.env.REACT_APP_APPLICATION_VERSION}</Typography>
);

export default withStyles(styles)(ApplicationVersion);
