import React from 'react';
import compose from 'recompose/compose';
import { actionCreators as DebtorDebtCollectionsStoreActionCreators } from '../../../store/DebtorDebtCollectionsStore';
import { actionCreators as DebtorInvoicesStoreActionCreators } from '../../../store/DebtorInvoicesStore';
import { actionCreators as DebtorLeapClaimsStoreActionCreators } from '../../../store/DebtorLeapClaimsStore';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorClaim, ResultOfDebtorIndex } from '../../../api/client';
import { getDebtorIdsFromQueryString, getInvoiceSystemDebtorIds, getLeapClaimSystemDebtorIds } from '../../../components/Utils';
import DebtorClaimComponent from '../../../components/debtorOverview/containers/DebtorClaim';
import { getDebtorClaims } from '../../../store/Selectors';

type ClassNames = 'isLoadingDebtorClaims' | 'noDebtorClaims';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  isLoadingDebtorClaims: {
    margin: '0 auto',
    display: 'block',
  },
  noDebtorClaims: {
    margin: '0 auto',
    width: 500,
    marginBottom: 10,
  },
});

interface PropsFromState {
  debtorClaims: DebtorClaim[];
  selectedResult?: ResultOfDebtorIndex | null;
  isLoadingDebtorClaims: boolean;
}

interface PropsFromDispatch {
  clearDebtorDebtCollections: typeof DebtorDebtCollectionsStoreActionCreators.clearDebtorDebtCollections;
  clearDebtorInvoices: typeof DebtorInvoicesStoreActionCreators.clearDebtorInvoices;
  clearDebtorLeapClaims: typeof DebtorLeapClaimsStoreActionCreators.clearLeapClaims;
  getDebtorDebtCollections: typeof DebtorDebtCollectionsStoreActionCreators.getDebtorDebtCollections;
  getDebtorInvoices: typeof DebtorInvoicesStoreActionCreators.getDebtorInvoices;
  getDebtorLeapClaims: typeof DebtorLeapClaimsStoreActionCreators.getLeapClaims;
}

interface InjectedProps extends RouteComponentProps<{}> {
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class DebtorClaimsList extends React.Component<ComponentProps> {
  componentDidMount() {
    const debtorIds: Array<string> = getDebtorIdsFromQueryString(this.props.location);
    this.props.getDebtorInvoices(getInvoiceSystemDebtorIds(debtorIds));
    this.props.getDebtorDebtCollections(getInvoiceSystemDebtorIds(debtorIds));
    this.props.getDebtorLeapClaims(getLeapClaimSystemDebtorIds(debtorIds));
  }

  componentWillUnmount() {
    this.props.clearDebtorInvoices();
    this.props.clearDebtorDebtCollections();
    this.props.clearDebtorLeapClaims();
  }

  public render() {
    return (
      <div>
        {this.props.isLoadingDebtorClaims ?
            <CircularProgress className={this.props.classes.isLoadingDebtorClaims} size={75} /> :
            this.props.debtorClaims.length === 0 &&
            <div className={this.props.classes.noDebtorClaims}>
              <Typography variant="h6">Debitor har ingen fakturaer eller inkassosaker</Typography>
            </div>
          }
          {this.props.debtorClaims.map((item, i) => <DebtorClaimComponent key={i} debtorClaim={item} isTopLevel={true} />)}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  debtorClaims: getDebtorClaims(state),
  selectedResult: state.debtorSearch.selectedResult,
  isLoadingDebtorClaims: state.debtorInvoices.isLoading || state.debtorDebtCollections.isLoading || state.debtorLeapClaims.isLoading,
});

const mapDispatchToProps: PropsFromDispatch = {
  clearDebtorDebtCollections: DebtorDebtCollectionsStoreActionCreators.clearDebtorDebtCollections,
  clearDebtorInvoices: DebtorInvoicesStoreActionCreators.clearDebtorInvoices,
  clearDebtorLeapClaims: DebtorLeapClaimsStoreActionCreators.clearLeapClaims,
  getDebtorDebtCollections: DebtorDebtCollectionsStoreActionCreators.getDebtorDebtCollections,
  getDebtorInvoices: DebtorInvoicesStoreActionCreators.getDebtorInvoices,
  getDebtorLeapClaims: DebtorLeapClaimsStoreActionCreators.getLeapClaims,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DebtorClaimsList));