import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import arrowLeft from '../../assets/images/icons/arrow-left.svg';

type ClassNames = 'container' | 'childContainer' | 'heading' | 'link' | 'arrowIcon';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& h1, & h2': {
      marginBottom: 24
    },
  },
  childContainer: {
    width: '75%',
    margin: '0 auto'
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  arrowIcon: {
    float: 'left',
    marginRight: 10
  }
});

type ComponentProps = WithStyles<ClassNames>;

const Forbidden: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.container}>
    <Typography className={props.classes.heading} variant="h3">403 - Oida, denne siden har du ikke tilgang til...</Typography>
    <div className={props.classes.childContainer}>
      <Typography variant="h6">Om du mener du skal ha tilgang, ta kontakt med din teamleder.</Typography>
      <Link to="/" className={props.classes.link}>
        <div>
          <img src={arrowLeft} alt="icon" className={props.classes.arrowIcon} />
          <Typography variant="h6" className={props.classes.link}>Gå til forsiden</Typography>
        </div>
      </Link>
    </div>
  </div>
);

export default withStyles(styles)(Forbidden);