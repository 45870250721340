import React, { useCallback, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorClaim, SelectedDebtorClaim } from '../../../api/client';
import { formatDate } from '../../../components/Utils';
import { colors } from '../../../Layout';
import { getChildInvoicesForDebtCollectionClaim, getConversationActions, isDebtorClaimSelectableForConversationSupervisorStep } from '../../../store/Selectors';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import { actionCreators as DebtorDebtCollectionsStoreActionCreators } from '../../../store/DebtorDebtCollectionsStore';
import { actionCreators as DebtorInvoicesStoreActionCreators } from '../../../store/DebtorInvoicesStore';
import { actionCreators as DebtorLeapClaimsStoreActionCreators } from '../../../store/DebtorLeapClaimsStore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DebtorClaimIcon from '../../debtorOverview/presentation/DebtorClaimIcon';
import DebtorClaimSystemBadge from '../../debtorOverview/presentation/DebtorClaimSystemBadge';
import DebtorPaymentInformation from '../../debtorOverview/presentation/DebtorPaymentInformation';
import DebtorClaimEventComponent from '../../debtorClaimEvents/presentation/DebtorClaimEvent';
import DebtorClaimChildren from '../../debtorOverview/presentation/DebtorClaimChildren';
import ExternalLinkToInvoice from '../../debtorOverview/presentation/ExternalLinkToInvoice';
import CreditorName from '../../debtorOverview/presentation/CreditorName';
import currencyFormatter from 'currency-formatter';
import classnames from 'classnames';
import { useAppDispatch } from '../../../hooks';

type ClassNames = 'actions' | 'badge' | 'badgeHidden' | 'card' | 'cardContent' | 'checkboxContainer' | 'debtorClaimEvents' | 'details' | 'detailsBottomRow' | 'expand' |
  'expandIconContainer' | 'expandOpen' | 'icon' | 'important' | 'isLoadingEvents' | 'numberAndCreditorName';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  actions: {
    display: 'flex',
  },
  badge: {
    display: 'flex',
    margin: '0 auto',
    width: '80%',
    maxWidth: 1000,
    minWidth: 700,
  },
  badgeHidden: {
    display: 'none',
  },
  card: {
    margin: '0 auto 16px auto',
    width: '100%',
    maxWidth: 1000,
    minWidth: 700,
    paddingBottom: 0,
  },
  cardContent: {
    display: 'flex',
    padding: 0,
    paddingBottom: 0
  },
  checkboxContainer: {
    padding: '1% 0',
  },
  container: {
    maxWidth: 1000,
    margin: '0 auto 16px auto',
    paddingBottom: 0
  },
  debtorClaimEvents: {
    maxWidth: '80%',
    margin: '0 auto',
    marginBottom: 50
  },
  details: {
    paddingLeft: 8,
    paddingBottom: 8,
    paddingTop: 8,
    width: '100%',
  },
  detailsBottomRow: {
    paddingTop: 15,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    padding: '10% 0',
  },
  expandIconContainer: {
    padding: '2% 0',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    padding: '10% 0',
  },
  icon: {
    flex: 1,
    paddingTop: '1em',
    paddingRight: '5px',
    paddingBottom: '1em',
    paddingLeft: '20px',
  },
  important: {
    fontWeight: 'bold',
  },
  isLoadingEvents: {
    margin: '0 auto',
    display: 'block',
  },
  numberAndCreditorName: {
    minWidth: 300,
  },
});

interface PropsFromState {
  expanded?: boolean;
  isLoadingEvents: boolean;
  isSelected: boolean;
  childClaims: DebtorClaim[];
  conversationActions: number;
  isSelectable: boolean;
}
interface InjectedProps {
  debtorClaim: DebtorClaim;
  isTopLevel: boolean;
}

type ComponentProps = PropsFromState  & InjectedProps & WithStyles<ClassNames>;
const DebtorClaimComponent = (props: ComponentProps) => {

  const { 
    expanded,
    isTopLevel,
    debtorClaim,
    conversationActions, 
    classes, 
    isSelectable,
    isSelected,
    childClaims,
  } = props;

  const [isExpanded, setIsExpanded] = useState(expanded ?? false);
  const bannerBackgroundColor = !isExpanded ? colors.lightPurple : colors.white;
  const collapsePanelBackgroundColor = isTopLevel ? colors.white : colors.lightGray;

  const dispatch = useAppDispatch();

  const toggleDebtorClaimSelection = useCallback((debtorClaim: SelectedDebtorClaim) => { 
    dispatch(DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection(debtorClaim)); 
  }, [dispatch]);

  const clearDebtorClaimsSelection = useCallback(() => { 
    dispatch(DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection()); 
  }, [dispatch]);
  
  const getLeapDebtCollectionEvents = useCallback((caseNumber: string) => { 
    dispatch(DebtorLeapClaimsStoreActionCreators.getLeapDebtCollectionEvents(caseNumber)); 
  }, [dispatch]);

  const getDebtCollectionEvents = useCallback((caseNumber: string) => { 
    dispatch(DebtorDebtCollectionsStoreActionCreators.getDebtCollectionEvents(caseNumber)); 
  }, [dispatch]);

  const getInvoiceEvents = useCallback((debtorId: string, referenceId: number, invoiceNumber: string) => { 
    dispatch(DebtorInvoicesStoreActionCreators.getInvoiceEvents(debtorId, referenceId, invoiceNumber)); 
  }, [dispatch]);

  const getLeapEvents  = useCallback((claimId: string) => { 
    dispatch(DebtorLeapClaimsStoreActionCreators.getLeapEvents(claimId)); 
  }, [dispatch]);

  useEffect(() => {
    clearDebtorClaimsSelection();
  }, [clearDebtorClaimsSelection])

  const handleExpandClick = useCallback(() => {
    setIsExpanded(!isExpanded);
    
    if (!debtorClaim.eventsHaveBeenFetched) {
      if (debtorClaim.type === 'Invoice') {
        getInvoiceEvents(debtorClaim.debtorId!.toString(), debtorClaim.eventsReferenceId, debtorClaim.number ? debtorClaim.number!.toString() : '');
      }
      else if (debtorClaim.type === 'DebtCollection') {
        getDebtCollectionEvents(debtorClaim.number!.toString());
      }
      else if (debtorClaim.type === 'LeapClaim') {
        getLeapEvents(debtorClaim.externalReferenceId!.toString());
      }
      else if (debtorClaim.type === 'LeapDebtCollectionClaim') {
        getLeapDebtCollectionEvents(debtorClaim.number!.toString());
      }
    }
  }, [
    debtorClaim.debtorId,
    debtorClaim.eventsHaveBeenFetched,
    debtorClaim.eventsReferenceId,
    debtorClaim.externalReferenceId,
    debtorClaim.number,
    debtorClaim.type,
    getDebtCollectionEvents, 
    getInvoiceEvents, 
    getLeapDebtCollectionEvents, 
    getLeapEvents, 
    isExpanded
  ]);

  const handleCheckboxChange = useCallback(() => {
    toggleDebtorClaimSelection({ number: debtorClaim.number!.toString(), type: debtorClaim.type!.toString(), externalReferenceId: debtorClaim.externalReferenceId! });
  }, [debtorClaim.externalReferenceId, debtorClaim.number, debtorClaim.type, toggleDebtorClaimSelection]);

  const enabledOpacity = 1;
  const disabledOpacity = 0.3;

  return (
    <Badge
      color="primary"
      badgeContent={conversationActions}
      className={classes.badge}
      classes={{ badge: conversationActions === 0 ? classes.badgeHidden : '' }}
    >
      <Card className={classes.card} style={{opacity: isSelectable ? enabledOpacity : disabledOpacity}} data-id="debtorClaimItem">
        <CardContent className={classes.cardContent} style={{ backgroundColor: bannerBackgroundColor }}>
          <DebtorClaimIcon variant={debtorClaim.typeAndStatus} />
            <div className={`${classes.details} ${classes.numberAndCreditorName}`}>
              <DebtorClaimSystemBadge system={debtorClaim.type} />
            { debtorClaim.type === 'Invoice' ?
              <ExternalLinkToInvoice debtorId={debtorClaim.debtorId!} invoiceNumber={debtorClaim.number!} /> :
                <Typography className={classes.important}>{debtorClaim.number}</Typography>
            }
            <CreditorName creditorName={debtorClaim.creditorName}/>
          </div>
          <div className={classes.details}>
            <Typography>{debtorClaim.type === 'DebtCollection' ? 'Registreringsdato' : 'Ordredato'}: {formatDate(debtorClaim.date)}</Typography>
          </div>
          <div className={classes.details}>
            <Typography />
            <Typography className={`${classes.detailsBottomRow} ${classes.important}`}>
              {
                debtorClaim.isClaimToAnotherPayer ? 'FTAB' :
                  debtorClaim.amount === null ? '' :
                    `Utestående:${currencyFormatter.format(debtorClaim.amount!, { code: 'NOK' })}`
              }
            </Typography>
          </div>
          <div className={classes.expandIconContainer}>
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: isExpanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={isExpanded}
              data-id="debtorClaimItemExpandButton"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
          <div className={classes.checkboxContainer}>
            <Checkbox color="default" checked={isSelected} onChange={handleCheckboxChange} disabled={!isSelectable} />
          </div>
        </CardContent>
        <CardActions style={{ display: 'none' }} />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit={true} style={{ backgroundColor: collapsePanelBackgroundColor }}>
          <DebtorPaymentInformation
            kid={debtorClaim.kid}
            accountNumber={debtorClaim.debtorPaymentAccount}
          />
          <div className={classes.debtorClaimEvents}>
            {debtorClaim.events && debtorClaim.events.map((item, i) => <DebtorClaimEventComponent key={i} event={item} debtorId={debtorClaim.debtorId!} />)}
          </div>
          {isTopLevel && <DebtorClaimChildren childClaims={childClaims} />}
        </Collapse>
      </Card>
    </Badge>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: ComponentProps): PropsFromState => ({
  isLoadingEvents: state.debtorInvoices.isLoadingEvents || state.debtorDebtCollections.isLoadingEvents,
  isSelected: state.debtorClaims.selectedDebtorClaims.map(x => x.number).indexOf(ownProps.debtorClaim.number!) !== -1,
  childClaims: getChildInvoicesForDebtCollectionClaim(state, ownProps.debtorClaim),
  conversationActions: getConversationActions(state, ownProps.debtorClaim),
  isSelectable: isDebtorClaimSelectableForConversationSupervisorStep(state.conversationSupervisor, ownProps.debtorClaim),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(DebtorClaimComponent);
