import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { formatPhoneNumber, urlify } from '../../../components/Utils';
import { colors } from '../../../Layout';
import LinkUnopenedIcon from '@material-ui/icons/PhonelinkOff';
import LinkOpenedIcon from '@material-ui/icons/Phonelink';
import LockedIcon from '@material-ui/icons/LockOutlined';
import NotReceivedIcon from '@material-ui/icons/CallMissedOutgoing';
import CheckIcon from '@material-ui/icons/Check';
import TimerIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';

type ClassNames = 'content';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  content: {
    whiteSpace: 'pre-line'
  },
  iconGreen: {
    verticalAlign: 'bottom',
    height: '0.9em',
    color: colors.green
  },
  iconRed: {
    verticalAlign: 'bottom',
    height: '0.9em',
    color: colors.darkred
  }
});

interface Props {
  header: string;
  content: string;
}

const getFormattedTooltipContent = (header: string, content: string, props) => {
  var formattedContent: any = content;
  var color = colors.black;

  switch (header) {

    case 'Mottaker':
      if (content) { formattedContent = formatPhoneNumber(content); }
      break;

    case 'Melding':
      if (!content) {
        color = colors.darkred;
        formattedContent = 'Melding ukjent';
        break;
      }
      formattedContent = <span dangerouslySetInnerHTML={{__html: urlify(content)}}/>;
      break;

    case 'Første pålogging':
      const opened = moment(content, 'DD.MM.YYYY HH:mm:ss').isValid();
      color = opened ? colors.green : colors.darkred;
      formattedContent = opened ?
        <><LinkOpenedIcon className={props.classes.iconGreen} /> {content}</> :
        <><LinkUnopenedIcon className={props.classes.iconRed} /> Ingen pålogging</>;
      break;

    case 'Bekreftet mottatt':
      const received = moment(content, 'DD.MM.YYYY HH:mm:ss').isValid();
      color = received ? colors.green : colors.darkred;
      formattedContent = received ?
        <><CheckIcon className={props.classes.iconGreen} />{content}</> :
        <><NotReceivedIcon className={props.classes.iconRed} />Ikke mottatt</>;
      break;

    case 'Utløpsdato':
      const expires = moment(content, 'DD.MM.YYYY HH:mm:ss');
      if (!expires.isValid()) { break; }
      const now = moment();
      const hasExpired = expires < now;
      const diff = expires.diff(now);
      color = hasExpired ? colors.darkred : colors.green;
      formattedContent = hasExpired ?
        <><LockedIcon className={props.classes.iconRed} />{expires.format('DD.MM.YYYY HH:mm:ss')}</> :
        <><TimerIcon className={props.classes.iconGreen} />{moment(diff).format('H')} timer og {moment(diff).format('m')} min igjen ({content})</>;
      break;

    default:
      break;
  }

  return <span style={{ color : color}}>{formattedContent}</span>;
};

type ComponentProps = Props & WithStyles<ClassNames>;

const TooltipContent: React.FunctionComponent<ComponentProps> = props => (
  <Typography className={props.classes.content}><b>{props.header}: </b>{getFormattedTooltipContent(props.header, props.content, props)}</Typography>
);

export default withStyles(styles)(TooltipContent);