import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorClaim } from '../../../../api/client';
import { hasSelectedDebtorClaims, getOutstandingDebtorClaims } from '../../../../store/Selectors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@material-ui/icons/ArrowRight';
import PreviousStep from './PreviousStep';

type ClassNames = 'listItem';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  listItem: {
    paddingRight: 0,
  }
});

interface PropsFromState {
  hasSelectedDebtorClaims: boolean;
  outstandingDebtorClaims: DebtorClaim[];
}

interface PropsFromDispatch {
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
  toggleDebtorClaimSelection: typeof DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class PaymentAgreement extends React.Component<ComponentProps> {

  componentDidMount() {
    this.props.clearDebtorClaimsSelection();
    this.props.outstandingDebtorClaims.forEach(
      debtorClaim => debtorClaim.type === 'Invoice' || debtorClaim.type === 'DebtCollection'
      ? this.props.toggleDebtorClaimSelection({ number: debtorClaim.number!.toString(), type: debtorClaim.type!.toString(), externalReferenceId: debtorClaim.externalReferenceId! })
      : null
    );
  }

  public render() {
    return (
      <div>
        <PreviousStep previousStep={() => this.props.jumpToStep(Step.Start)} />
        <List>
          <ListItem className={this.props.classes.listItem} button={true} disabled={!this.props.hasSelectedDebtorClaims} onClick={() => this.props.jumpToStep(Step.PaymentInformation)}>
            <ListItemText primary="Fullt oppgjør/resterende" />
            <ArrowRight />
          </ListItem>
        </List>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(state.debtorClaims),
  outstandingDebtorClaims: getOutstandingDebtorClaims(state.debtorInvoices, state.debtorLeapClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
  toggleDebtorClaimSelection: DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentAgreement);