import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { SelectedDebtorClaim, ConfirmDebtorClaimsAsClosedAction } from '../../../../api/client';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { hasSelectedDebtorClaims } from '../../../../store/Selectors';
import PreviousStep from './PreviousStep';

type ClassNames = 'listItem';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  listItem: {
    paddingRight: 0,
  }
});

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class HasPaid extends React.Component<ComponentProps> {

  handleConfirmDebtorClaimsAsClosed = () => {
    const action: ConfirmDebtorClaimsAsClosedAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      discriminator: 'ConfirmDebtorClaimsAsClosedAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <div>
        <PreviousStep previousStep={() => this.props.jumpToStep(Step.Start)} />
        <List>
          <ListItem className={this.props.classes.listItem} disabled={!this.props.hasSelectedDebtorClaims} button={true} onClick={() => this.props.jumpToStep(Step.PaymentNotRegistered)}>
            <ListItemText primary="Betaling ikke mottatt" />
            <ArrowRight />
          </ListItem>
          <ListItem className={this.props.classes.listItem} disabled={!this.props.hasSelectedDebtorClaims} button={true} onClick={this.handleConfirmDebtorClaimsAsClosed}>
            <ListItemText primary="OK - Bekrefter sak avsluttet" />
            <ArrowRight />
          </ListItem>
        </List >
      </div>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(debtorClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(HasPaid);