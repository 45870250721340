import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { ResultOfDebtorIndex } from '../../../api/client';
import Person from '@material-ui/icons/Person';
import { formatNationalIdentityNumber, formatPhoneNumber } from '../../../components/Utils';
import { history } from '../../../AppHistory';
import { actionCreators as ConversationSupervisingStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import Button from '../../../components/common/Button';
import DebtorSearchResultReason from './DebtorSearchResultReason';
import PowerOfAttorney from '../../../components/powerOfAttorney/PowerOfAttorney';
import { actionCreators as DebtorSearchActionCreators } from '../../../store/DebtorSearchStore';

type ClassNames = 'card' | 'cardContent' | 'cardContentSections' | 'buttonRow' | 'personIcon' | 'personName' | 'link';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  card: {
    width: 750,
    margin: '10px auto'
  },
  cardContent: {
    minHeight: 100
  },
  cardContentSections: {
    marginBottom: 10
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  personIcon: {
    float: 'left'
  },
  personName: {
    float: 'left',
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 'bold'
  },
  link: {
    textDecoration: 'none'
  }
});

interface Props {
  item: ResultOfDebtorIndex;
  resetConversationSupervisor: typeof ConversationSupervisingStoreActionCreators.resetConversationSupervisor;
  resetDebtorClaims: typeof DebtorClaimsStoreActionCreators.resetDebtorClaims;
  selectDebtor: typeof DebtorSearchActionCreators.selectDebtor;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorSearchResultItem: React.FunctionComponent<ComponentProps> = props => {
  const handleRedirect = () => {
    props.resetConversationSupervisor();
    props.resetDebtorClaims();
    props.selectDebtor(props.item);
    history.push(`/debtorlookup`);
  };

  return (
    <Card raised={true} className={props.classes.card}>
      <CardContent className={props.classes.cardContent}>
        <div className={props.classes.cardContentSections}>
          <Person className={props.classes.personIcon} />
          <Typography variant="h6" className={props.classes.personName}>{props.item.document!.name}</Typography>
          <Typography variant="subtitle1">
            {formatNationalIdentityNumber(props.item.document!.nationalIdentityNumber) || props.item.document!.dateOfBirth}
          </Typography>
        </div>
        <div className={props.classes.cardContentSections}>
          <Typography>{props.item.document!.street}</Typography>
          <Typography>{props.item.document!.postCode} {props.item.document!.postArea}</Typography>
          <Typography>{props.item.document!.country}</Typography>
        </div>
        <div className={props.classes.cardContentSections}>
          {props.item.document!.phoneNumbers && props.item.document!.phoneNumbers!.map((item, i) => <Typography key={i}>{formatPhoneNumber(item)}</Typography>)}
        </div>
        <div className={props.classes.buttonRow}>
          <PowerOfAttorney debtor={props.item} />
          <Button onClick={handleRedirect}>Legitimasjon</Button>
          <Button onClick={handleRedirect}>Ringer selv</Button>
        </div>
        <Divider />
        <DebtorSearchResultReason highlights={props.item.highlights} />
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(DebtorSearchResultItem);