import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/icons/logo-white.svg';

type ClassNames = 'image';

const styles = {
  image: {
    height: 50
  },
};

interface Props {
  userProfile?: string;
  defaultPath: string;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const Logo: React.FunctionComponent<ComponentProps> = props => (
  <Link to={props.defaultPath}>
    <img src={logo} className={props.classes.image} alt="logo" />
  </Link>
);

export default withStyles(styles)(Logo);