import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import securePage from '../../components/authentication/SecurePage';
import queryString from 'query-string';

interface PropsFromRoute extends RouteComponentProps<{}> {
}

interface ComponentProps {
}

export const IncomingCall: React.FunctionComponent<ComponentProps & PropsFromRoute> = props => {
  let searchCriteria = '';
  if (props.location.search !== undefined) {
    const parsedQueryString = queryString.parse(props.location.search);
    if (parsedQueryString.phoneNumber !== undefined && parsedQueryString.phoneNumber !== null) {
      searchCriteria = queryString.stringify({ searchcriteria: parsedQueryString.phoneNumber });
    }
  }

  return (
    <Redirect to={`/search/?${searchCriteria}`} />
  );
};

export default (securePage(withRouter(IncomingCall)));