import React from 'react';
import { history } from '../../../AppHistory';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { SubmittedConversation } from '../../../store/ConversationSupervisorStore';
import { createDebtorIdsQueryString, formatEpochToTime } from '../../../components/Utils';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Lock from '@material-ui/icons/Lock';

type ClassNames = 'debtorName' | 'header' | 'lock' | 'progress' | 'root' | 'table' | 'time';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  debtorName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  header: {
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
  },
  lock: {
    width: 10,
  },
  progress: {
    display: 'inlineBlock',
    marginTop: 20,
  },
  root: {
    padding: 10,
    width: 400,
    textAlign: 'center',
  },
  table: {
    tableLayout: 'fixed',
  },
  time: {
    width: '25%',
  },
});

interface Props {
  submittedConversations: SubmittedConversation[];
}

type ComponentProps = Props & WithStyles<ClassNames>;

const handleClick = (conversation) => {
  if (!conversation.noAvailablePowerOfAttorney) {
    history.push('/debtor?' + createDebtorIdsQueryString(conversation.relatedDebtorIds));
  }
};

const SubmittedConversations: React.FunctionComponent<ComponentProps> = props =>
  (
    <div className={props.classes.root}>
      <Typography className={props.classes.header}>Samtaler i dag</Typography>
      <Table className={props.classes.table} padding="dense">
        <TableHead>
          <TableRow>
            <TableCell className={props.classes.lock} />
            <TableCell>Debitor</TableCell>
            <TableCell align="right" className={props.classes.time}>Tidspunkt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.submittedConversations.map((conversation, i) => {
            return (
              <TableRow key={i} hover={true} onClick={() => handleClick(conversation)}>
                <TableCell>{conversation.noAvailablePowerOfAttorney && <Lock />}</TableCell>
                <TableCell className={props.classes.debtorName}>{conversation.debtorName}</TableCell>
                <TableCell align="right" className={props.classes.time}>{formatEpochToTime(conversation.submittedAt)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );

export default withStyles(styles)(SubmittedConversations);