import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';

type ClassNames = 'heading' | 'headingText';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  heading: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
  },
  headingText: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: '8px'
  },
});

type ComponentProps = WithStyles<ClassNames>;

const DebtorLogHeader: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.heading}>
    <Typography className={props.classes.headingText}>Logg</Typography>
  </div>
);

export default withStyles(styles)(DebtorLogHeader);