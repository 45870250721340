import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { authenticatedAsAdministrator, authenticatedAsCustomerCareWorker, authenticatedAsTerminalWorker } from '../../store/AuthenticationStore';
import { actionCreators as MenuNavigationActionCreators } from '../../store/MenuNavigation';

interface PropsFromState {
  isAuthenticated: boolean;
  roles: string[];
  administrator: boolean;
  customerCareWorker: boolean;
  terminalWorker: boolean;
}

interface PropsFromDispatch {
  setMenuPath: typeof MenuNavigationActionCreators.setPath;
}

type ComponentProps = PropsFromState & PropsFromDispatch;

const defaultPageHoc = Page => class DefaultPage extends React.Component<ComponentProps> {
  updateMenuPath(path: string) {
    switch (path) {
      case '/search':
      case '/debtor':
      case '/callcenter/incoming':
        this.props.setMenuPath('search');
        break;
      case '/terminal':
        this.props.setMenuPath('terminal');
        break;
      case '/administration':
        this.props.setMenuPath('administration');
        break;
      default:
        this.props.setMenuPath('');
        break;
    }
  }

  render() {
    this.updateMenuPath(window.location.pathname);
    return (
      <Page {...this.props} />
    );
  }
};

const mapStateToProps = ({ authentication }: ApplicationState): PropsFromState => ({
  isAuthenticated: authentication.isAuthenticated,
  roles: authentication.roles,
  administrator: authenticatedAsAdministrator(authentication),
  customerCareWorker: authenticatedAsCustomerCareWorker(authentication),
  terminalWorker: authenticatedAsTerminalWorker(authentication),
});

const mapDispatchToProps: PropsFromDispatch = {
  setMenuPath: MenuNavigationActionCreators.setPath
};

const connectedDefaultPage = Page => compose(connect(mapStateToProps, mapDispatchToProps))(defaultPageHoc(Page));

export default connectedDefaultPage;