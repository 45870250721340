import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { Step } from './ConversationSupervisorStep';
import ArrowRight from '@material-ui/icons/ArrowRight';
import NoteIcon from '@material-ui/icons/CreateOutlined';
import SummaryIcon from '@material-ui/icons/Notes';
import ObjectionIcon from '@material-ui/icons/PauseCircleOutline';
import CreditIcon from '@material-ui/icons/MoneyOff';
import InterruptedIcon from '@material-ui/icons/CallEndOutlined';
import PaidIcon from '@material-ui/icons/ReceiptOutlined';
import PaymentIcon from '@material-ui/icons/CreditCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import StepZilla from 'react-stepzilla';

type ClassNames = 'listItem';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  listItem: {
    paddingRight: 0,
  }
});

interface Props {
  hasConversationSupervisorActions: boolean;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = Props & InjectedProps & WithStyles<ClassNames>;

const ConversationStart: React.FunctionComponent<ComponentProps> = props => (
  <List>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.HasPaid)}>
      <PaidIcon />
      <ListItemText primary="Har betalt" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.PaymentAgreement)}>
      <PaymentIcon />
      <ListItemText primary="Skal betale" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.Objection)}>
      <ObjectionIcon />
      <ListItemText primary="Har innsigelse" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.Credit)}>
      <CreditIcon />
      <ListItemText primary="Vil kreditere" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.OtherReason)}>
      <NoteIcon />
      <ListItemText primary="Notat" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.ConversationInterrupted)}>
      <InterruptedIcon />
      <ListItemText primary="Samtalen avbrutt" />
      <ArrowRight />
    </ListItem>
    <ListItem className={props.classes.listItem} button={true} onClick={() => props.jumpToStep(Step.Summary)} disabled={!props.hasConversationSupervisorActions}>
      <SummaryIcon />
      <ListItemText primary="Oppsummering" />
      <ArrowRight />
    </ListItem>
  </List>
);

export default withStyles(styles)(ConversationStart);