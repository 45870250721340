import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';

type ClassNames = 'container';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    marginTop: 10
  }
});

interface ComponentProps {
  previousStep: () => void;
}

const PreviousStep: React.FunctionComponent<ComponentProps & WithStyles<ClassNames>> = props => (
  <Button className={props.classes.container} onClick={props.previousStep}>
    <ArrowBack />
  </Button>
);

export default withStyles(styles)(PreviousStep);