import React from 'react';
import { history } from '../../AppHistory';
import securePage from '../../components/authentication/SecurePage';
import { withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { getStartingPageForLoginWithRoles } from '../Utils';

type ClassNames = 'container';

const styles: StyleRules<ClassNames> = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 20,
  }
};

interface PropsFromState {
  roles: string[];
  isAuthenticated: boolean;
}

type ComponentProps = PropsFromState & WithStyles<ClassNames>;

class LayoutComponent extends React.Component<ComponentProps> {
  componentDidMount() {
    console.group('RootNode');
    const startPageForRole = getStartingPageForLoginWithRoles(this.props.roles);
    const localStorageRedirectTo = localStorage.getItem('authorization.redirectTo');
    const redirect =
      (
        localStorageRedirectTo && localStorageRedirectTo !== '/'
          ? localStorageRedirectTo
          : startPageForRole
            ? startPageForRole
            : '/forbidden'
      );
    console.log('rootnode redirecting to:', redirect, localStorageRedirectTo, startPageForRole, this.props.roles, this.props.isAuthenticated);
    console.groupEnd();
    if (!this.props.isAuthenticated) {
      history.push('/login');
    } else {
      history.push(redirect);
    }
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <CircularProgress size={75} />
        <Typography>Prøver å finne startsiden din.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(securePage(LayoutComponent));