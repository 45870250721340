import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../Layout';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AvailablePowerOfAttorney } from '../../../api/client';

type ClassNames = 'appBar' | 'toolBar';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  appBar: {
    backgroundColor: colors.orange,
  },
  toolBar: {
    minHeight: 0,
    justifyContent: 'center'
  }
});

interface PropsFromState {
  selectedPowerOfAttorney: AvailablePowerOfAttorney | null;
}

interface PropsFromDispatch {
  clearPowerOfAttorneys: typeof PowerOfAttorneyStoreActionCreators.clearPowerOfAttorneys;
}

interface InjectedProps extends RouteComponentProps<{}> {
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class DebtorSelectedPowerOfAttorneyBanner extends React.Component<ComponentProps> {

  componentWillUnmount() {
    this.props.clearPowerOfAttorneys();
  }

  public render() {
    return (
      <div>
        {this.props.selectedPowerOfAttorney ?
          <AppBar position="sticky" className={this.props.classes.appBar}>
            <Toolbar variant="dense" className={this.props.classes.toolBar}>
              <Typography variant="caption" color="inherit">
                {'På vegne av: ' + this.props.selectedPowerOfAttorney.onBehalfOf + ', ' +
                  'får: ' + this.props.selectedPowerOfAttorney.grantTo + ' ' +
                  (this.props.selectedPowerOfAttorney.includeAccess === true ?
                    this.props.selectedPowerOfAttorney.includeAgreement === true ? 'innsyn og avtale' : 'innsyn' :
                    this.props.selectedPowerOfAttorney.includeAgreement === true ? 'avtale' : '') +
                  ', med varighet: ' + this.props.selectedPowerOfAttorney.duration}
              </Typography>
            </Toolbar>
          </AppBar>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  selectedPowerOfAttorney: state.powerOfAttorney.selectedPowerOfAttorney
});

const mapDispatchToProps: PropsFromDispatch = {
  clearPowerOfAttorneys: PowerOfAttorneyStoreActionCreators.clearPowerOfAttorneys,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DebtorSelectedPowerOfAttorneyBanner));
