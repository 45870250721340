import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { ResultOfDebtorIndex } from '../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import CircularProgress from '@material-ui/core/CircularProgress';
import DebtorSearchResultItem from '../../../components/debtorSearch/presentation/DebtorSearchResultItem';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'progress' | 'noResults' | 'noResultsContainer' | 'results';

const styles = {
  progress: {
    width: '75px',
    margin: '0 auto',
  },
  noResults: {
    margin: '0 auto',
  },
  noResultsContainer: {
    display: 'flex',
  },
  results: {
    width: '95%',
    margin: '0 auto',
  },
};

interface PropsFromState {
  criteria: string;
  isSearching: boolean;
  results?: ResultOfDebtorIndex[];
}

interface PropsFromDispatch {
  resetConversationSupervisor: typeof ConversationSupervisorStoreActionCreators.resetConversationSupervisor;
  resetDebtorClaims: typeof DebtorClaimsStoreActionCreators.resetDebtorClaims;
  selectDebtor: typeof DebtorSearchStoreActionCreators.selectDebtor;
}

type ComponentProps = PropsFromState & PropsFromDispatch & WithStyles<ClassNames>;

class DebtorSearchResults extends React.Component<ComponentProps> {
  public render() {
    const noResults = !!this.props.criteria && (!this.props.results || this.props.results!.length < 1);

    return (
      <div>
        {this.props.isSearching &&
          <div className={this.props.classes.progress}>
            <CircularProgress size={75} />
          </div>
        }

        {!this.props.isSearching && noResults &&
          <div className={this.props.classes.noResultsContainer}>
            <Typography variant="h5" className={this.props.classes.noResults}>Søk etter '{this.props.criteria}' ga ingen treff</Typography>
          </div>
        }
        
        {!this.props.isSearching && !noResults &&
          <div className={this.props.classes.results} data-id="searchResults">
            {this.props.results!.map((item, i) =>
              <DebtorSearchResultItem 
                key={i} 
                item={item} 
                resetConversationSupervisor={this.props.resetConversationSupervisor} 
                resetDebtorClaims={this.props.resetDebtorClaims}
                selectDebtor={this.props.selectDebtor}
              />)
            }
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ debtorSearch }: ApplicationState): PropsFromState => ({
  criteria: debtorSearch.criteria,
  isSearching: debtorSearch.isSearching,
  results: debtorSearch.results ? debtorSearch.results.results : []
});

const mapDispatchToProps: PropsFromDispatch = {
  resetConversationSupervisor: ConversationSupervisorStoreActionCreators.resetConversationSupervisor,
  resetDebtorClaims: DebtorClaimsStoreActionCreators.resetDebtorClaims,
  selectDebtor: DebtorSearchStoreActionCreators.selectDebtor
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DebtorSearchResults);