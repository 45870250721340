import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import { ResultOfDebtorIndex } from '../../../api/client';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '../../../components/common/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actionCreators as DebtorSearchActionCreators } from '../../../store/DebtorSearchStore';

type ClassNames = 'container' | 'buttonRow';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
});

interface InjectedProps {
  toggleModal: () => void;
  debtor: ResultOfDebtorIndex;
}

interface PropsFromDispatch {
  selectDebtor: typeof DebtorSearchActionCreators.selectDebtor;
  registerPowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.registerPowerOfAttorney;
}

interface State {
  onBehalfOf: string;
  grantTo: string;
  duration: string;
  includeAccess: boolean;
  includeAgreement: boolean;
  sentPowerOfAttorneyRegistration: boolean;
}

type ComponentProps = PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class RegisterNewPowerOfAttorney extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      onBehalfOf: '',
      grantTo: '',
      duration: '',
      includeAccess: false,
      includeAgreement: false,
      sentPowerOfAttorneyRegistration: false
    };
  }

  isValid() {
    return !!this.state.onBehalfOf.trim() && !!this.state.grantTo.trim() && !!this.state.duration.trim() && (this.state.includeAccess || this.state.includeAgreement);
  }

  handleOnBehalfOfChange = event => {
    this.setState({ onBehalfOf: event.target.value });
  }

  handleGrantToChange = event => {
    this.setState({ grantTo: event.target.value });
  }

  handlePowerOfAttorneyDurationChange = event => {
    this.setState({ duration: event.target.value });
  }

  handleTypeChange = name => event => {
    if (name === 'includeAccess') {
      this.setState({ includeAccess: event.target.checked });
    }
    else {
      this.setState({ includeAgreement: event.target.checked, includeAccess: !this.state.includeAccess && event.target.checked ? true : this.state.includeAccess });
    }
  }

  handleOK = () => {
    this.setState({
      sentPowerOfAttorneyRegistration: true
    });
    this.props.selectDebtor(this.props.debtor);
    this.props.registerPowerOfAttorney(
      this.props.debtor.document!.id!,
      this.state.onBehalfOf,
      this.state.grantTo,
      this.state.duration,
      this.state.includeAccess,
      this.state.includeAgreement
    );
  }
  public render() {
    return (
      <div className={this.props.classes.container}>
        <TextField
          label="Hvem gir fullmakten?"
          placeholder="Fullt navn og fødselsdato"
          autoFocus={true}
          required={false}
          margin="normal"
          variant="outlined"
          onChange={this.handleOnBehalfOfChange}
        />
        <TextField
          label="Hvem får fullmakten?"
          placeholder="Fullt navn og fødselsdato"
          required={false}
          margin="normal"
          variant="outlined"
          onChange={this.handleGrantToChange}
        />
        <Typography>Hva omfattes av fullmakten?</Typography>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.includeAccess}
                onChange={this.handleTypeChange('includeAccess')}
                value="access"
                color="primary"
              />
            }
            label="Innsyn"
          />
          <FormControlLabel
            control={
              <Switch
                checked={this.state.includeAgreement}
                onChange={this.handleTypeChange('includeAgreement')}
                value="agreement"
                color="primary"
              />
            }
            label="Avtale"
          />
        </div>
        <TextField
          label="Varighet av fullmakten?"
          required={false}
          margin="normal"
          variant="outlined"
          onChange={this.handlePowerOfAttorneyDurationChange}
        />
        <div className={this.props.classes.buttonRow}>
          <Button onClick={() => this.props.toggleModal()}>Avbryt</Button>
          <Button onClick={() => this.handleOK()} disabled={!this.isValid() || this.state.sentPowerOfAttorneyRegistration}>
            {this.state.sentPowerOfAttorneyRegistration ? (
              <CircularProgress size={20} />
            ) : 'OK'}
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  registerPowerOfAttorney: PowerOfAttorneyStoreActionCreators.registerPowerOfAttorney,
  selectDebtor: DebtorSearchActionCreators.selectDebtor
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(RegisterNewPowerOfAttorney);