import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { colors } from '../../../Layout';
import { ClaimEventType } from '../../../api/client';
import DebtorClaimEventIcon from '../../debtorClaimEvents/presentation/DebtorClaimEventIcon';
import DebtorClaimEventDetails from './DebtorClaimEventDetails';
import TooltipContent from '../presentation/TooltipContent';
import { StyleRulesCallback, withStyles, WithStyles } from '@material-ui/core/styles';
import { actionCreators as DebtorClaimDownloadStoreActionCreators } from '../../../store/DebtorClaimDownloadStore';

type ClassNames = 'downloadCursor';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  downloadCursor: {
    cursor: 'pointer'
  }
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: colors.marble,
    color: theme.palette.primary.dark,
    boxShadow: theme.shadows[2],
    fontSize: '0.875rem',
    fontWeight: 500,
    maxWidth: 600,
    marginTop: 4,
    padding: 10
  },
}))(Tooltip);

interface PropsFromDispatch {
  downloadDebtorClaimPDF: typeof DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF;
}

interface InjectedProps {
  debtorId: string;
  eventId: string;
  eventType: ClaimEventType;
  eventTooltip: { [key: string]: string; } | undefined;
}

type ComponentProps = PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class DebtorClaimEventDetailsTooltip extends React.Component<ComponentProps> {

  downloadClaimPDF = () => {
    this.props.downloadDebtorClaimPDF(this.props.debtorId, this.props.eventId, this.props.eventType);
  }

  public render() {

    const disableHover = (
      [
        ClaimEventType.PaymentInformationSentBySms,
        ClaimEventType.PaymentInformationSentByEmail,
        ClaimEventType.ObjectionRegistered
      ].indexOf(this.props.eventType!) === -1

      && !this.props.eventTooltip
    );

    const disableDownload = (
      [
        ClaimEventType.Letter,
        ClaimEventType.DebtCollectionWarningLetter,
        ClaimEventType.InvoiceSmsReceived,
        ClaimEventType.InvoiceSmsNotReceived,
        ClaimEventType.DigiPostInvoice,
        ClaimEventType.DigiPostDebtCollectionWarning,
      ].indexOf(this.props.eventType!) === -1
    );

    return (
        <HtmlTooltip
          title={
              this.props.eventTooltip && this.props.eventTooltip !== undefined ?
              Object.keys(this.props.eventTooltip!).map((key) => (<TooltipContent key={key} header={key} content={this.props.eventTooltip![key]} /> )) :
              <DebtorClaimEventDetails id={this.props.eventId} />
          }
          disableHoverListener={disableHover}
          placement="bottom-start"
          interactive={true}
          enterDelay={100}
          leaveDelay={200}
          PopperProps={{style: { opacity: 1 }}}
        >
          <span onClick={!disableDownload ? this.downloadClaimPDF : undefined} className={!disableDownload ? this.props.classes.downloadCursor : undefined}>
            <DebtorClaimEventIcon eventType={this.props.eventType}/>
          </span>
        </HtmlTooltip>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  downloadDebtorClaimPDF: DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(DebtorClaimEventDetailsTooltip);