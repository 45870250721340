import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { actionCreators, Notification } from '../../store/NotificationsStore';
import NotificationSnackbarContent from './NotificationSnackbarContent';

type ClassNames = 'message' | 'icon';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  }
});

interface PropsFromState {
  notifications: Notification[];
}

interface PropsFromDispatch {
  removeNotification: typeof actionCreators.removeNotification;
}

interface State {
  open: boolean;
}

type ComponentProps = PropsFromState & PropsFromDispatch & WithStyles<ClassNames>;

export class NotificationsSnackbar extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: this.props.notifications.length > 0,
    };
  }

  componentDidUpdate() {
    if (!this.state.open && this.props.notifications.length > 0) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.props.removeNotification();
    this.setState({ open: false });
  }

  public render() {
    return (
      <div>
        {this.state.open && this.props.notifications[0] &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={this.state.open}
            autoHideDuration={3000}
            onClose={this.handleClose}
          >
            <NotificationSnackbarContent notification={this.props.notifications[0]} onClose={this.handleClose} />
          </Snackbar>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ notifications }: ApplicationState): PropsFromState => ({
  notifications: notifications.messages,
});

const mapDispatchToProps: PropsFromDispatch = {
  removeNotification: actionCreators.removeNotification,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsSnackbar);