import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import MaterialUiButton from '@material-ui/core/Button';

type ClassNames = 'button';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  button: {
    margin: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textTransform: 'none',
    letterSpacing: '0.5px'
  }
});

type ComponentProps = any & WithStyles<ClassNames>;

const Button: React.FunctionComponent<ComponentProps> = props => {
  const { classes, ...other } = props;

  return (
    <MaterialUiButton variant="contained" className={props.classes.button} {...other}>
      {props.children}
    </MaterialUiButton>
  );
};

export default withStyles(styles)(Button);