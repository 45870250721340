import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { colors } from '../../../../Layout';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { RegisterCreditNoteConversationAction, CreditType, SelectedDebtorClaim, DebtorClaim } from '../../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { hasSelectedDebtorClaims, getDebtorClaimsEligibleForCredit } from '../../../../store/Selectors';
import { Step } from '../ConversationSupervisorStep';
import Button from '../../../../components/common/Button';
import StepZilla from 'react-stepzilla';

type ClassNames = 'noteContainer' | 'note' | 'buttonsContainer' | 'radioGroupHeader' | 'radioFormControlLabel';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  noteContainer: {
    paddingTop: 10,
  },
  note: {
    minWidth: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  radioGroupHeader: {
    marginBottom: '10px',
    marginTop: '25px',
    color: colors.black
  },
  radioFormControlLabel: {
    marginBottom: '-10px'
  }
});

const creditTypes = {
  'FullInvoice' : {value: CreditType.FullInvoice, label: 'Hele fakturaen'},
  'AllInvoiceFees' : {value: CreditType.AllInvoiceFees, label: 'Fakturagebyret'},
};

const CreditRadio = withStyles({
  colorSecondary: {
      color: colors.darkGray,
      '&$checked': {
          color: colors.primary.bondiblue,
        },
  },
  checked: {}
})(Radio);

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
  debtorClaimsEligibleForCredit: DebtorClaim[];
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
  toggleDebtorClaimSelection: typeof DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  noteText: string;
  creditType: CreditType | undefined;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class Credit extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      noteText: '',
      creditType: undefined,
    };
  }

  isValid = () => {
    return this.state.noteText.trim() && this.props.hasSelectedDebtorClaims && this.state.creditType;
  }

  handleTextFieldChange = event => {
    this.setState({ noteText: event.target.value });
  }

  handleRadioButtonClick = event => {
    if (event.target.checked === true) {
      this.setState({ creditType: event.target.value });
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const action: RegisterCreditNoteConversationAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      reason: this.state.noteText,
      creditType: this.state.creditType!,
      discriminator: 'RegisterCreditNoteConversationAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  componentDidMount() {
    this.props.selectedDebtorClaims.forEach(
      selectedDebtorClaim => this.props.debtorClaimsEligibleForCredit.every(
        eligibleClaim => eligibleClaim.number !== selectedDebtorClaim.number)
        ? this.props.toggleDebtorClaimSelection( {number: selectedDebtorClaim.number!.toString(), type: selectedDebtorClaim.type!.toString(), externalReferenceId: selectedDebtorClaim.externalReferenceId!})
        : null
      );
  }

  public render() {
    return (
      <div>
        <FormControl>
          <FormLabel className={this.props.classes.radioGroupHeader}>Hva vil du kreditere?</FormLabel>
          <RadioGroup>
            {
              Object.keys(creditTypes).map((key) => (
                <FormControlLabel key={key} className={this.props.classes.radioFormControlLabel} value={creditTypes[key].value.toString()} control={<CreditRadio key={key} onClick={this.handleRadioButtonClick} />} label={creditTypes[key].label} />
              ))
            }
          </RadioGroup>
        </FormControl>
        <div className={this.props.classes.noteContainer}>
          <TextField
            label="Årsak"
            autoFocus={true}
            required={true}
            error={!this.isValid()}
            multiline={true}
            margin="normal"
            className={this.props.classes.note}
            value={this.state.noteText}
            onChange={this.handleTextFieldChange}
          />
        </div>
        <div className={this.props.classes.buttonsContainer}>
          <Button onClick={() => this.props.jumpToStep(Step.Start)}>Avbryt</Button>
          <Button id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">OK</Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: state.debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(state.debtorClaims),
  debtorClaimsEligibleForCredit: getDebtorClaimsEligibleForCredit(state),
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
  toggleDebtorClaimSelection: DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Credit);