import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'appBar' | 'toolBar' | 'text';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  appBar: {
    backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    marginTop: '10px',
    display: 'block',
    minHeight: '40px'
  },
  toolBar: {
    justifyContent: 'center',
    cursor: 'pointer'
  },
  text: {
    color: `${theme.palette.secondary.main}`
  }
});

type ComponentProps = WithStyles<ClassNames>;

function reload() {
  window.location.reload();
}

const NewVersionAvailableBar: React.FunctionComponent<ComponentProps> = props => {
  return (
    <AppBar position="sticky" className={props.classes.appBar}>
      <Toolbar variant="dense" className={props.classes.toolBar} onClick={() => reload()} >
        <Typography variant="h6" color="inherit">
          Jippi. Ny versjon er tilgjengelig. Trykk her eller F5 for å oppdatere!
          </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(NewVersionAvailableBar);
