import React from 'react';
import { ApplicationState } from '../../store';
import { history } from '../../AppHistory';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { authenticatedAsCustomerCareWorker, authenticatedAsAdministrator, authenticatedAsTerminalWorker } from '../../store/AuthenticationStore';

interface PropsFromState {
  roles: string[];
  administrator: boolean;
  customerCareWorker: boolean;
  terminalWorker: boolean;
  menuNavigationPathValue: string;
}

type ComponentProps = PropsFromState;

class ApplicationBarMenu extends React.Component<ComponentProps> {
  hangleMenuNavigationChange = (event: object, newValue: string): void => {
    console.log('applicationBarMenu hangleMenuNavigationChange', event, newValue);
    history.push(`/${newValue}`);
  }

  render() {
    return (
      <>
        {this.props.menuNavigationPathValue && ([this.props.customerCareWorker, this.props.terminalWorker].filter(i => i === true).length > 1 || this.props.administrator) ?
          (
            <Tabs value={this.props.menuNavigationPathValue} onChange={this.hangleMenuNavigationChange}>
              {this.props.administrator ? <Tab value="administration" label="Administrator" /> : null}
              {this.props.customerCareWorker ? <Tab value="search" label="Kundesenter" /> : null}
              {this.props.terminalWorker ? <Tab value="terminal" label="Terminal" /> : null}
            </Tabs>
          ) : null
        }
      </>
    );
  }
}

const mapStateToProps = ({ authentication, menuNavigation }: ApplicationState): PropsFromState => ({
  roles: authentication.roles,
  administrator: authenticatedAsAdministrator(authentication),
  customerCareWorker: authenticatedAsCustomerCareWorker(authentication),
  terminalWorker: authenticatedAsTerminalWorker(authentication),
  menuNavigationPathValue: menuNavigation.path
});

export default connect(mapStateToProps)(ApplicationBarMenu);
