import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { ConversationAction } from '../../../api/client';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getConversationActionSummary, getConversationActionName } from '../../../api/actions';

type ClassNames = 'summaryText';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  summaryText: {
    overflowWrap: 'break-word',
  }
});

export interface Props {
  index: number;
  item: ConversationAction;
  conversationWasInterrupted: boolean;
  removeConversationAction: typeof actionCreators.removeConversationAction;
}

export const getSecondaryText = (item: ConversationAction) => {
  const summary = getConversationActionSummary(item);
  return item.selectedDebtorClaims
    ? item.selectedDebtorClaims.map(x => x.number).join(', ') + ': ' + summary
    : summary;
};

type ComponentProps = Props & WithStyles<ClassNames>;

export const handleDelete = (props: Props) => {
  props.removeConversationAction(props.index);
};

export const ConversationSummaryItem: React.FunctionComponent<ComponentProps> = props => {
  return (
    <ListItem>
      <ListItemIcon>
        {props.conversationWasInterrupted
          ? <CheckBoxOutlineBlankIcon />
          : <CheckBoxIcon />
        }
      </ListItemIcon>
      <ListItemText primary={getConversationActionName(props.item)} secondary={getSecondaryText(props.item)} className={props.classes.summaryText} />
      <ListItemSecondaryAction>
        <IconButton aria-label="Slett" onClick={() => handleDelete(props)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(ConversationSummaryItem);