import React from 'react';
import compose from 'recompose/compose';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../store/PowerOfAttorneyStore';
import { history } from '../../AppHistory';
import { AvailablePowerOfAttorney } from '../../api/client';
import { ResultOfDebtorIndex } from '../../api/client';
import Button from '../../components/common/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RegisterNewPowerOfAttorney from '../../components/powerOfAttorney/containers/RegisterNewPowerOfAttorney';
import ExistingPowerOfAttorneys from '../../components/powerOfAttorney/containers/ExistingPowerOfAttorneys';
import NoPowerOfAttorney from '../../components/powerOfAttorney/containers/NoPowerOfAttorney';
import { getExistingPowerOfAttorneys } from '../../store/Selectors';
import { actionCreators as DebtorSearchActionCreators } from '../../store/DebtorSearchStore';

type ClassNames = 'buttonRow' | 'heading' | 'progress' | 'root' | 'details' | 'modalStyle';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  heading: {
    fontWeight: 'bold',
  },
  progress: {
    display: 'block',
    margin: '30px auto 0px auto',
  },
  root: {
    position: 'absolute',
    width: theme.spacing.unit * 75,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  details: {
    display: 'block'
  },
  modalStyle: {
    overflowY: 'scroll'
  }
});

const getModalContentStyle = () => {
  return {
    top: `126px`,
    left: `50%`,
    transform: `translate(-50%, 0px)`,
  };
};

interface PropsFromState {
  isLoading: boolean;
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
}

interface PropsFromDispatch {
  getAvailablePowerOfAttorneys: typeof PowerOfAttorneyStoreActionCreators.getAvailablePowerOfAttorneys;
  clearSelectedPowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.clearSelectedPowerOfAttorney;
  selectDebtor: typeof DebtorSearchActionCreators.selectDebtor;
}

interface InjectedProps {
  debtor: ResultOfDebtorIndex;
}

interface State {
  isModalOpen: boolean;
  expandedPanel: Panels;
}

enum Panels {
  RegisterNewPowerOfAttorney = 0,
  NoPowerOfAttorney = 1,
  ExistingPowerOfAttorneys = 2,
  InsightWithoutPowerOfAttorney = 3,
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class PowerOfAttorney extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      expandedPanel: Panels.RegisterNewPowerOfAttorney
    };
  }

  toggleModal = () => {
    this.props.clearSelectedPowerOfAttorney();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  handleOK = () => {
    this.props.selectDebtor(this.props.debtor);
    history.push(`/debtorlookup`);
  }

  selectPanel = selectedPanel => (event, expanded) => {
    this.setState({
      expandedPanel: expanded ? selectedPanel : false,
    });
  }

  public render() {
    const { expandedPanel } = this.state;

    return (
      <div>
        <Button onClick={() => { this.toggleModal(); this.props.getAvailablePowerOfAttorneys(this.props.debtor.document!.id!); }}>Annen ringer</Button>
        <Modal
          className={this.props.classes.modalStyle}
          aria-labelledby="annen-ringer"
          aria-describedby="annen-ringer"
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
        >
          <div style={getModalContentStyle()} className={this.props.classes.root}>
            <Typography variant="h6" className={this.props.classes.heading}>
              Fullmakt
            </Typography>
            <ExpansionPanel expanded={expandedPanel === Panels.RegisterNewPowerOfAttorney} onChange={this.selectPanel(Panels.RegisterNewPowerOfAttorney)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Registrer ny fullmakt</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={this.props.classes.details}>
                <RegisterNewPowerOfAttorney toggleModal={this.toggleModal} debtor={this.props.debtor} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expandedPanel === Panels.NoPowerOfAttorney} onChange={this.selectPanel(Panels.NoPowerOfAttorney)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Har ikke fullmakt</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={this.props.classes.details}>
                <NoPowerOfAttorney toggleModal={this.toggleModal} debtor={this.props.debtor.document!} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {this.props.isLoading
              ? <CircularProgress className={this.props.classes.progress} size={50} />
              : this.props.availablePowerOfAttorneys && this.props.availablePowerOfAttorneys.length > 0 &&
              <React.Fragment>
                <ExpansionPanel expanded={expandedPanel === Panels.ExistingPowerOfAttorneys} onChange={this.selectPanel(Panels.ExistingPowerOfAttorneys)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Eksisterende fullmakter ({this.props.availablePowerOfAttorneys.length})</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={this.props.classes.details}>
                    <ExistingPowerOfAttorneys toggleModal={this.toggleModal} handleOk={this.handleOK} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </React.Fragment>
            }
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  isLoading: state.powerOfAttorney.isLoading,
  availablePowerOfAttorneys: getExistingPowerOfAttorneys(state),
});

const mapDispatchToProps: PropsFromDispatch = {
  getAvailablePowerOfAttorneys: PowerOfAttorneyStoreActionCreators.getAvailablePowerOfAttorneys,
  clearSelectedPowerOfAttorney: PowerOfAttorneyStoreActionCreators.clearSelectedPowerOfAttorney,
  selectDebtor: DebtorSearchActionCreators.selectDebtor
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PowerOfAttorney);
