import React from 'react';
import compose from 'recompose/compose';
import { ApplicationState } from '../../../store';
import { actionCreators as DebtorLogItemsStoreActionCreators } from '../../../store/DebtorLogItemsStore';
import { connect } from 'react-redux';
import { DebtorLogItem, SelectedDebtorClaim } from '../../../api/client';
import { getDebtorIdsFromQueryString } from '../../../components/Utils';
import { getDebtorLogItems } from '../../../store/Selectors';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DebtorLogItemComponent from '../../../components/debtorLog/presentation/DebtorLogItem';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'container' | 'isLoading' | 'noLogItems' | 'log';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    left: '30px',
    right: '30px',
    display: 'block',
    height: '100%',
  },
  noLogItems: {
    margin: '16px',
    fontSize: '14px'
  },
  isLoading: {
    margin: '16px auto',
    display: 'block',
  },
  log: {
    flexGrow: 1,
    overflowY: 'auto',
  },
});

const topRowHeight = 333;

interface PropsFromState {
  logItems: DebtorLogItem[];
  isLoading: boolean;
  selectedDebtorClaims: SelectedDebtorClaim[];  
}

interface State {
  height: number;
}

interface PropsFromDispatch {
  clearDebtorLogItems: typeof DebtorLogItemsStoreActionCreators.clearDebtorLogItems;
  getDebtorLogItems: typeof DebtorLogItemsStoreActionCreators.getDebtorLogItems;
}

interface InjectedProps extends RouteComponentProps<{}> {
}

type ComponentProps = State & PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class DebtorLog extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    
    this.state = {
      height: window.innerHeight
    };
  }

  updateWindowHeight = () => {
    this.setState({
      height: window.innerHeight
    });
  }

  componentDidMount() {
    const debtorIds = getDebtorIdsFromQueryString(this.props.location);
    this.props.getDebtorLogItems(debtorIds);
    window.addEventListener('resize', this.updateWindowHeight, false);
  }  

  componentWillUnmount() {
    this.props.clearDebtorLogItems();
    window.removeEventListener('resize', this.updateWindowHeight, false);
  }

  public render() {    
    return (
      <div className={this.props.classes.container}>         
        {this.props.isLoading ?
          <CircularProgress className={this.props.classes.isLoading} /> :
          this.props.logItems.length === 0 &&
          <Typography className={this.props.classes.noLogItems}>Debitor har ingen logg</Typography>
        }        
        <div className={this.props.classes.log} style={{ maxHeight: this.state.height - topRowHeight }}>
          {this.props.logItems &&
            this.props.logItems.map((item, i) => 
            <DebtorLogItemComponent
              key={i} 
              logItem={item} 
              selected={item.debtorClaimNumbers ? item.debtorClaimNumbers!.filter(o => this.props.selectedDebtorClaims.filter(n => n.number === o).length > 0).length > 0 : false}
            />)          
          }
        </div>          
      </div>
      );
    }
  }

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  logItems: getDebtorLogItems(state),
  selectedDebtorClaims: state.debtorClaims.selectedDebtorClaims,
  isLoading: state.debtorLogItems.isLoading,
});

const mapDispatchToProps: PropsFromDispatch = {
  clearDebtorLogItems: DebtorLogItemsStoreActionCreators.clearDebtorLogItems,
  getDebtorLogItems: DebtorLogItemsStoreActionCreators.getDebtorLogItems,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DebtorLog));