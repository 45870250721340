import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators as DebtorOverviewStoreActionCreators } from '../../../store/DebtorOverviewStore';
import { getDebtorIdsFromQueryString } from '../../../components/Utils';
import { getTotalOutstanding } from '../../../store/Selectors';
import { DebtorIndex } from '../../../api/client';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Debtor from '../../../components/debtorOverview/presentation/Debtor';

type ClassNames = 'isLoadingDebtor';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  isLoadingDebtor: {
    margin: '0 auto',
    marginTop: 50,
    display: 'block',
  },
});

interface PropsFromState {
  debtor: DebtorIndex | null;
  outstanding: number;
  isLoadingDebtor: boolean;
}

interface PropsFromDispatch {
  getDebtor: typeof DebtorOverviewStoreActionCreators.getDebtor;
}

interface InjectedProps extends RouteComponentProps<{}> {
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

class DebtorBanner extends React.Component<ComponentProps> {
  componentDidMount() {
    this.props.getDebtor(getDebtorIdsFromQueryString(this.props.location)[0]);
  }

  public render() {
    return (
      <div>
        {this.props.isLoadingDebtor ?
          <CircularProgress className={this.props.classes.isLoadingDebtor} size={75} /> :
          <Debtor debtor={this.props.debtor} outstanding={this.props.outstanding} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  debtor: state.debtorOverview.debtor,
  isLoadingDebtor: state.debtorOverview.isLoading,
  outstanding: getTotalOutstanding(state),
});

const mapDispatchToProps: PropsFromDispatch = {
  getDebtor: DebtorOverviewStoreActionCreators.getDebtor,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DebtorBanner));
