import * as React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Logo from '../../components/logo/Logo';
import ApplicationVersion from '../../components/applicationBar/ApplicationVersion';
import ApplicationBarMenu from '../../components/applicationBar/ApplicationBarMenu';

type ClassNames = 'appBar' | 'toolbar' | 'version' | 'logo' | 'menu' | 'account';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  appBar: {
    backgroundImage: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
  },
  toolbar: {
    minHeight: 88,
  },
  version: {
    position: 'absolute',
    top: '0px',
    right: '24px'
  },
  logo: {
    paddingLeft: 144,
  },
  menu: {
    paddingLeft: 144,
  },
  account: {
    marginLeft: 'auto',
    fontSize: 24,
    color: `${theme.palette.secondary.main}`
  }
});

interface Props {
  userProfile?: string;
  defaultPath: string;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const ApplicationBar: React.FunctionComponent<ComponentProps> = props => (
  <AppBar position="static" className={props.classes.appBar}>
    <Toolbar className={props.classes.toolbar}>
      <div className={props.classes.logo}>
        <Logo defaultPath={props.defaultPath} />
      </div>
      <div className={props.classes.menu}>
        <ApplicationBarMenu />
      </div>
      <Typography className={props.classes.account}>{props.userProfile}</Typography>
      <div className={props.classes.version}>
        <ApplicationVersion />
      </div>
    </Toolbar>
  </AppBar>
);

export default withStyles(styles)(ApplicationBar);
