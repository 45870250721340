import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorLogItem } from '../../../api/client';
import { formatDateLong } from '../../../components/Utils';
import { colors } from '../../../Layout';

type ClassNames = 'container' | 'date' | 'debtorClaimNumber' | 'text' | 'user' | 'log';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    width: '100%',
    margin: '10px 0 10px 0'
  },
  date: {
    fontSize: '10px',
    textAlign: 'center'
  },
  debtorClaimNumber: {
    fontWeight: 'bold',
  },
  text: {
    borderRadius: '1px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
    fontSize: '14px',
    margin: '8px',
    padding: '8px'
  },
  user: {
    fontSize: '10px',
    color: colors.gray,
    textAlign: 'right',
    margin: '0 8px 0 0'
  },
  log: {
    whiteSpace: 'pre-wrap'
  },
});

const backgroundColor = colors.marble;
const selectedBackgroundColor = colors.secondary.lemon;

interface Props {
  logItem: DebtorLogItem;
  selected: boolean;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorLogItemComponent: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.container}>
    <Typography className={props.classes.date}>{formatDateLong(props.logItem.registeredAt)}</Typography>
    <div className={props.classes.text} style={{ backgroundColor: props.selected ? selectedBackgroundColor : backgroundColor }}>
      {props.logItem.debtorClaimNumbers && props.logItem.debtorClaimNumbers.map((item, i) => <Typography key={i} className={props.classes.debtorClaimNumber}>{item}{'\n'}</Typography>)}
      <Typography className={props.classes.log}>{props.logItem.text}</Typography>
    </div>
    <Typography className={props.classes.user}>{props.logItem.registeredBy}</Typography>
  </div>
);

export default withStyles(styles)(DebtorLogItemComponent);