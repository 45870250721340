import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/ConversationSupervisorStore';
import { colors } from '../../Layout';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import ConversationSupervisor from '../../components/conversationSupervisor/components/ConversationSupervisor';
import DebtorBanner from '../../components/debtorOverview/containers/DebtorBanner';
import DebtorSelectedPowerOfAttorneyBanner from '../../components/debtorOverview/containers/DebtorSelectedPowerOfAttorneyBanner';
import DebtorClaimsList from '../../components/debtorOverview/containers/DebtorClaimsList';
import DebtorLog from '../../components/debtorLog/containers/DebtorLog';
import DebtorLogHeader from '../../components/debtorLog/presentation/DebtorLogHeader';
import customerCareWorkerPage from '../../components/authentication/CustomerCareWorkerPage';
import SubmittedConversationsDrawer from '../../components/submittedConversations/containers/SubmittedConversationsDrawer';
import { actionCreators as DebtorNationalIdentificationNumberSearchActionCreators } from '../../store/DebtorNationalIdentificationNumberSearchStore';

type ClassNames = 'container' | 'conversationSupervisor' | 'debtor' | 'debtorClaims' | 'debtorLog' | 'placeholder' | 'splitter' | 'submittedConversations' | 'debtorLogHeader';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '400px 1fr 520px',
    gridTemplateRows: '220px 1px 1fr',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  conversationSupervisor: {
    gridColumnStart: '3',
    gridRowStart: '1',
    gridRowEnd: '3',
    position: 'sticky',
    top: 0,
    left: 20,
    paddingRight: 10,
    paddingTop: 67,
    minHeight: 442,
  },
  debtor: {
    gridColumnStart: '2',
    margin: '0 auto 0 auto',
    maxWidth: '1000px',
    width: '100%',
  },
  debtorClaims: {
    gridColumnStart: '2',
    gridRowStart: '3',
    padding: '20px',
    backgroundColor: colors.lightGray,
    minHeight: '55vh',
  },
  debtorLogHeader: {
    gridColumnStart: '1',
    gridRowStart: '1',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  debtorLog: {
    gridColumnStart: '1',
    gridRowStart: '3',
  },
  placeholder: {
    gridColumnStart: '3',
    gridRowStart: '3',
    backgroundColor: colors.lightGray
  },
  splitter: {
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
    boxShadow: '0 -1px 1px 0 rgba(0, 0, 0, 0.30)',
    gridRowStart: 2,
    gridColumnStart: 1,
    gridColumnEnd: 4
  },
  submittedConversations: {
    gridColumnStart: '3',
    gridRowStart: '3',
    position: 'fixed',
    bottom: 0,
    right: 10,
  },
});

interface PropsFromDispatch {
  resetConversationSupervisor: typeof actionCreators.resetConversationSupervisor;
  clearSearchDebtorsByNationalIdentificationNumberResults: typeof DebtorNationalIdentificationNumberSearchActionCreators.clearSearchDebtorsByNationalIdentificationNumberResults;
}

interface PropsFromRoute extends RouteComponentProps<{}> {
}

interface State {
  key?: H.LocationKey;
}

type ComponentProps = PropsFromDispatch & PropsFromRoute & WithStyles<ClassNames>;

class DebtorOverview extends React.Component<ComponentProps, State> {
  state = {
      key: undefined,
  };

  componentDidMount() {
    this.props.clearSearchDebtorsByNationalIdentificationNumberResults();
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.setState({ key: this.props.location.key });
      this.props.resetConversationSupervisor();
    }
  }

  public render() {
    return (
      <div>
        <div>
          <DebtorSelectedPowerOfAttorneyBanner key={this.state.key} />
        </div>
        <div className={this.props.classes.container}>
          <div className={this.props.classes.debtor}>
            <DebtorBanner key={this.state.key} />
          </div>
          <div className={this.props.classes.splitter} />
          <div className={this.props.classes.debtorLogHeader}>
            <DebtorLogHeader/>
          </div>
          <div className={this.props.classes.debtorLog}>
            <DebtorLog key={this.state.key} />
          </div>
          <div className={this.props.classes.debtorClaims}>
            <DebtorClaimsList key={this.state.key} />
          </div>
          <div className={this.props.classes.conversationSupervisor} >
            <ConversationSupervisor key={this.state.key} />
          </div>
          <div className={this.props.classes.submittedConversations}>
            <SubmittedConversationsDrawer />
          </div>
          <div className={this.props.classes.placeholder} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  resetConversationSupervisor: actionCreators.resetConversationSupervisor,
  clearSearchDebtorsByNationalIdentificationNumberResults: DebtorNationalIdentificationNumberSearchActionCreators.clearSearchDebtorsByNationalIdentificationNumberResults
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(customerCareWorkerPage(withRouter(DebtorOverview)));