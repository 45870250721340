import { parsePhoneNumber } from 'awesome-phonenumber';
import queryString from 'query-string';
import moment from 'moment';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/da';
import * as H from 'history';

export const invoiceSystemPrefix = 'mmsys';
export const leapSystemPrefix = 'leap';

export const formatNationalIdentityNumber = (nationalIdentityNumber: string | undefined | null) => {
  if (nationalIdentityNumber === undefined || nationalIdentityNumber === null || nationalIdentityNumber.length <= 6) {
    return nationalIdentityNumber || '';
  }

  return nationalIdentityNumber.slice(0, 6) + ' ' + nationalIdentityNumber.slice(6);
};

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber === undefined || phoneNumber.length <= 6) {
    return phoneNumber || '';
  }

  const regEx = new RegExp('^\\+?[0-9 ]+$'); // Only format phone numbers that may start with a plus sign and then only contains numbers or whitespaces.
  if (!regEx.test(phoneNumber)) {
    return phoneNumber;
  }

  return parsePhoneNumber(phoneNumber, { regionCode: 'NO' }).number?.international;
};

export const formatDate = (date: Date | undefined, locale: string = 'nb') => {
  if (date === undefined) {
    return '';
  }

  return moment(date).locale(locale).format('L');
};

export const formatDateLong = (date: Date | undefined, locale: string = 'nb') => {
  if (date === undefined) {
    return '';
  }

  return moment(date).locale(locale).format('DD. MMMM YYYY HH:mm:ss');
};

export const formatTime = (date: Date | undefined, locale: string = 'nb') => {
  if (date === undefined) {
    return '';
  }

  return moment(date).locale(locale).format('LT');
};

export const formatEpochToTime = (milliseconds: number, locale: string = 'nb') => {
  return moment(milliseconds).locale(locale).format('LT');
};

export const createDebtorIdsQueryString = (debtorIds?: string[]) => {
  if (debtorIds === undefined) {
    return '';
  }

  return queryString.stringify({ debtorId: debtorIds });
};

export const getDebtorIdsFromQueryString = (location?: H.Location) => {
  if (location === undefined) {
    return [];
  }

  const parsedQueryString = queryString.parse(location!.search);

  if (parsedQueryString.debtorId === undefined) {
    return [];
  } else if (typeof parsedQueryString.debtorId === 'string') {
    return [parsedQueryString.debtorId];
  } else {
    return parsedQueryString.debtorId ?? [];
  }
};

export const getInvoiceSystemDebtorIds = (ids: string[]) => {
  return ids.filter(x => x.includes(invoiceSystemPrefix));
};

export const getLeapClaimSystemDebtorIds = (ids: string[]) => {
  return ids.filter(x => x.includes(leapSystemPrefix));
};

export const getStartingPageForLoginWithRoles = (roles: string[]) => {
  let redirectTo = '/';
  if (roles && roles.some(i => i === 'CustomerCareWorker' || i === 'Administrator')) {
    redirectTo = '/search';
  } else if (roles && roles.some(i => i === 'TerminalWorker')) {
    redirectTo = '/terminal';
  }
  console.log('getStartingPageForLoginWithRoles', roles, redirectTo);

  return redirectTo;
};

export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return String(text).replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
};