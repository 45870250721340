import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { formatDate } from '../../../components/Utils';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '../../../components/common/Button';
import { AvailablePowerOfAttorney } from '../../../api/client';
import { ApplicationState } from '../../../store';
import { getExistingPowerOfAttorneys } from '../../../store/Selectors';
import DeactivatePowerOfAttorneyDialog from '../../../components/powerOfAttorney/containers/DeactivatePowerOfAttorneyDialog';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'container' | 'buttonRow' | 'formControlLabelWrapper' | 'deactivatedFormControlLabelWrapper' | 'formControlLabelDiv';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formControlLabelWrapper: {
    display: 'inline-flex',
    borderBottom: '1px solid #ccc',
    padding: '12px 0px',
  },
  deactivatedFormControlLabelWrapper: {
    display: 'inline-flex',
    borderBottom: '1px solid #ccc',
    padding: '12px 0px',
    backgroundColor: 'rgba(151, 151, 151, 0.11)',
  },
  formControlLabelDiv: {
    display: 'inline-flex',
  },
});

interface InjectedProps {
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
  toggleModal: () => void;
  handleOk: () => void;
}
interface PropsFromDispatch {
  registerSelectedPowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.registerSelectedPowerOfAttorney;
}

interface PropsFromState {
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
  selectedPowerOfAttorney: AvailablePowerOfAttorney | null;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;

export class ExistingPowerOfAttorneys extends React.Component<ComponentProps> {
  handlePowerOfAttorneySelection = (powerOfAttorney: AvailablePowerOfAttorney) => (event) => {
    this.props.registerSelectedPowerOfAttorney(powerOfAttorney);
  }

  isValid() {
    return !!this.props.selectedPowerOfAttorney;
  }

  formatAccess(includeAccess: boolean, includeAgreement: boolean) {
    return `${includeAccess && !includeAgreement ? 'innsyn' : includeAccess && includeAgreement ? 'innsyn og avtale' : !includeAccess && includeAgreement ? 'avtale' : ''}`;
  }
  public render() {
    return (
      <React.Fragment>
        <RadioGroup>
          {this.props.availablePowerOfAttorneys.map((item, i) =>
            <FormControlLabel
              value={i.toString()}
              key={i}
              className={item.active ? this.props.classes.formControlLabelWrapper : this.props.classes.deactivatedFormControlLabelWrapper}
              control={<Radio color="primary" />}
              onChange={this.handlePowerOfAttorneySelection(item)}
              disabled={!item.active}
              label={
                <div className={this.props.classes.formControlLabelDiv}>
                  <div>
                    <Typography inline={true}>
                      Registrert {this.formatAccess(item.includeAccess, item.includeAgreement)}, {formatDate(item.registeredAtUtc)} av {item.registeredBy}:
                    </Typography><br />
                    <Typography inline={true}><i>Fullmakt gis av </i>: {item.onBehalfOf}</Typography><br />
                    <Typography inline={true}><i>Fullmakt gis til </i>: {item.grantTo}</Typography><br />
                    <Typography inline={true}><i>Med varighet </i>: {item.duration}</Typography><br />
                    {!item.active && item.deactivatedAtUtc &&
                      <Typography inline={true}>
                        <strong>
                          Deaktivert {formatDate(new Date(item.deactivatedAtUtc))} av {item.deactivatedBy}
                        </strong>
                      </Typography>}
                  </div>
                  {item.active && <DeactivatePowerOfAttorneyDialog powerOfAttorney={item} />}
                </div>}
            />
          )}
        </RadioGroup>
        <div className={this.props.classes.buttonRow}>
          <Button onClick={() => this.props.toggleModal()}>Avbryt</Button>
          <Button onClick={() => this.props.handleOk()} disabled={!this.isValid()}>OK</Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  registerSelectedPowerOfAttorney: PowerOfAttorneyStoreActionCreators.registerSelectedPowerOfAttorney,
};

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  availablePowerOfAttorneys: getExistingPowerOfAttorneys(state),
  selectedPowerOfAttorney: state.powerOfAttorney.selectedPowerOfAttorney
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ExistingPowerOfAttorneys);