import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { NoAvailablePowerOfAttorneyConversationAction } from '../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import { DebtorIndex } from '../../../api/client';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/common/Button';

type ClassNames = 'container' | 'buttonRow';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
});

interface PropsFromDispatch {
  addConversationAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearSearchResultsAction: typeof DebtorSearchStoreActionCreators.clearSearchResults;
  submitConversation: typeof ConversationSupervisorStoreActionCreators.submitConversation;
}

interface InjectedProps {
  toggleModal: () => void;
  debtor: DebtorIndex;
}

interface State {
  powerOfAttorneyText: string;
}

type ComponentProps = PropsFromDispatch & InjectedProps & WithStyles<ClassNames>;
export class NoPowerOfAttorney extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      powerOfAttorneyText: '',
    };
  }

  isValid() {
    return !!this.state.powerOfAttorneyText.trim();
  }

  handlePowerOfAttorneyTextChange = event => {
    this.setState({ powerOfAttorneyText: event.target.value });
  }

  handleOK = () => {
    const action: NoAvailablePowerOfAttorneyConversationAction = {
      text: this.state.powerOfAttorneyText,
      discriminator: 'NoAvailablePowerOfAttorneyConversationAction',
    };

    this.props.addConversationAction(action);
    this.props.submitConversation(this.props.debtor.id, this.props.debtor.name, true);
    this.props.clearSearchResultsAction();
    this.props.toggleModal();
  }
  public render() {
    return (
      <div className={this.props.classes.container}>
        <TextField
          label="Beskrivelse"
          required={false}
          multiline={true}
          margin="normal"
          variant="outlined"
          value={this.state.powerOfAttorneyText}
          onChange={this.handlePowerOfAttorneyTextChange}
        />
        <div className={this.props.classes.buttonRow}>
          <Button onClick={() => this.props.toggleModal()}>Avbryt</Button>
          <Button onClick={() => this.handleOK()} disabled={!this.isValid()}>OK</Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  addConversationAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearSearchResultsAction: DebtorSearchStoreActionCreators.clearSearchResults,
  submitConversation: ConversationSupervisorStoreActionCreators.submitConversation,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(NoPowerOfAttorney);