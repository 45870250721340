import { filter, forEach, groupBy } from 'lodash/collection';
import { ApplicationState } from '.';
import { DebtorClaimStatus, DebtorClaim, AvailablePowerOfAttorney, DebtorLogItem, Invoice, LeapClaim } from '../api/client';
import { DebtorInvoicesState } from './DebtorInvoicesStore';
import moment from 'moment';
import { StepsGroup, steps } from '../components/conversationSupervisor/components/ConversationSupervisorStep';
import { DebtorClaimsState } from './DebtorClaimsStore';
import { DebtorLeapClaimsState } from './DebtorLeapClaimsStore';
import { ConversationSupervisorState } from './ConversationSupervisorStore';

export const getTotalOutstanding = (state: ApplicationState) => {
  return state.debtorInvoices.invoices
    .concat(state.debtorLeapClaims.claims.filter(x => x.amount && ((x.type === 'LeapClaim' && !x.debtCollectionExternalReferenceNumber) || x.type === 'LeapDebtCollectionClaim')))
    .filter(x => x.amount && !x.isClaimToAnotherPayer).reduce((a, b) => +a + +b.amount!, 0)
    + state.debtorDebtCollections.debtCollections.filter(x => x.amount && !x.isClaimToAnotherPayer).reduce((a, b) => +a + +b.amount!, 0);
};

export const getChildInvoicesForDebtCollectionClaim = (state: ApplicationState, debtorClaim: DebtorClaim) => {
  if (debtorClaim.type === 'DebtCollection') {
    return state.debtorInvoices.invoices.filter(x => x.debtCollectionExternalReferenceNumber && x.debtCollectionExternalReferenceNumber === debtorClaim.debtCollectionExternalReferenceNumber);
  } else if (debtorClaim.type === 'LeapDebtCollectionClaim') {
    return state.debtorLeapClaims.claims.filter(x => x.type === 'LeapClaim' && x.debtCollectionExternalReferenceNumber && x.debtCollectionExternalReferenceNumber === debtorClaim.debtCollectionExternalReferenceNumber);
  }

  return [];
};

export const getOutstandingDebtorClaims = (debtorInvoices: DebtorInvoicesState, debtorLeapClaims: DebtorLeapClaimsState) => {
  return debtorInvoices.invoices
    .concat(debtorLeapClaims.claims)
    .filter(x => x.amount! > 0 && x.status === DebtorClaimStatus.Active && x.isClaimToAnotherPayer === false);
};

export const getDebtorClaimsForObjectionRegistration = (state: ApplicationState) => {
  return state.debtorInvoices.invoices
    .filter(x => x.canRegisterObjection)
    .concat(state.debtorDebtCollections.debtCollections.filter(x => x.canRegisterObjection));
};

export const getDebtorClaimsEligibleForCredit = (state: ApplicationState) => {
  return state.debtorLeapClaims.claims
    .filter(x => x.canRegisterCreditNote);
};

export const getDebtorLogItems = (state: ApplicationState) => {

  const logItems = Array<DebtorLogItem>();

  const dateTime = item => moment(item.registeredAt).format('YYYY-MM-DD HH');
  const groupedLogItemsByRegisteredAt = groupBy(state.debtorLogItems.logItems, dateTime);
  forEach(groupedLogItemsByRegisteredAt, groupByRegisteredAt => {
    const groupedLogItemsByRegisteredBy = groupBy(groupByRegisteredAt, 'registeredBy');
    forEach(groupedLogItemsByRegisteredBy, groupByRegisteredBy => {
      const groupedLogItemsByText = groupBy(groupByRegisteredBy, 'text');
      forEach(groupedLogItemsByText, groupByText => {

        let currentLogItem;

        forEach(groupByText.valueOf() as Array<DebtorLogItem>, logItem => {

          if (!currentLogItem || !currentLogItem.debtorClaimNumbers) {
            currentLogItem = logItem;
          } else {
            forEach(logItem.debtorClaimNumbers, claimNumber => {
              if (!currentLogItem.debtorClaimNumbers.includes(claimNumber)) {
                currentLogItem.debtorClaimNumbers.push(claimNumber);
              }
            });
          }
        });

        if (currentLogItem) {
          logItems.push(currentLogItem);
        }

      });
    });
  });

  const selectedDebtorClaims = state.debtorClaims.selectedDebtorClaims;

  return logItems.sort((a, b): number => { if (a.registeredAt <= b.registeredAt) { return 1; } else { return -1; } })
    .sort((a, b): number => {
      return (a.debtorClaimNumbers && a.debtorClaimNumbers!.filter(o => selectedDebtorClaims.filter(n => n.number === o).length > 0).length > 0) ===
      (b.debtorClaimNumbers && b.debtorClaimNumbers!.filter(o => selectedDebtorClaims.filter(n => n.number === o).length > 0).length > 0) ? 0 :
      a.debtorClaimNumbers && a.debtorClaimNumbers!.filter(o => selectedDebtorClaims.filter(n => n.number === o).length > 0).length > 0 ? -1 : 1;
      }
  );
};

export const getConversationActions = (state: ApplicationState, debtorClaim: DebtorClaim) => {
  let actionCount = 0;
  filter(state.conversationSupervisor.conversation.actions, (element) => {
    actionCount += filter(element.selectedDebtorClaims, { number: debtorClaim.number, type: debtorClaim.type }).length;
  });

  return actionCount;
};

export const getSubmittedConversations = (state: ApplicationState) => {
  return state.conversationSupervisor.submittedConversations
    .filter(conversations => { return moment(conversations.submittedAt) >= moment().startOf('day'); })
    .sort((a, b): number => { return a.submittedAt <= b.submittedAt ? 1 : -1; });
};

const getSortingPriorityFromStatus = (status: DebtorClaimStatus) => {
  switch (status) {
    case DebtorClaimStatus.Active:
    case DebtorClaimStatus.BookedAsLost:
      return 100;
    case DebtorClaimStatus.ClosedDueToMinor:
      return 90;
    case DebtorClaimStatus.DebtCollection:
      return 80;
    case DebtorClaimStatus.Halted:
    case DebtorClaimStatus.ObjectionReceived:
      return 60;
    case DebtorClaimStatus.Credited:
    case DebtorClaimStatus.Cancelled:
    case DebtorClaimStatus.Forgiven:
      return 50;
    case DebtorClaimStatus.Paid:
      return 10;
    case DebtorClaimStatus.Expired:
      return 0;
    default:
      return -1;
  }
};

function sortDebtorClaims<T extends DebtorClaim>(a: T, b: T) {
  const sortingPriorityA = getSortingPriorityFromStatus(a.status);
  const sortingPriorityB = getSortingPriorityFromStatus(b.status);

  if (sortingPriorityA === sortingPriorityB) {
    if (a.date < b.date) {
      return 1;
    }
    else if (a.date > b.date) {
      return -1;
    }
    return 0;
  }

  return sortingPriorityA > sortingPriorityB ? -1 : 1;
};

export const getDebtorClaims = (state: ApplicationState) => {
  return (state.debtorInvoices.invoices.filter(x => x.debtCollectionExternalReferenceNumber == null)
    .concat(state.debtorLeapClaims.claims.filter(x => (x.type === 'LeapClaim' && x.debtCollectionExternalReferenceNumber == null) || x.type === 'LeapDebtCollectionClaim'))
    .concat(state.debtorDebtCollections.debtCollections)
    ).sort(sortDebtorClaims);
};

const filterAwayClaimsWithoutNumberOrKid = (invoice: Invoice | LeapClaim) => !!invoice.kid && !!invoice.number; 

export const getActiveLeapClaims = (state: ApplicationState) => {
  return state.debtorLeapClaims.claims
    .filter(x => ((x.type === 'LeapClaim' && x.debtCollectionExternalReferenceNumber == null) || x.type === 'LeapDebtCollectionClaim') 
            && filterAwayClaimsWithoutNumberOrKid(x) 
            && x.isTopLevelClaim 
            && !x.isClaimToAnotherPayer
            && (x.status === DebtorClaimStatus.Active || x.status === DebtorClaimStatus.DebtCollection || x.status === DebtorClaimStatus.Halted))
    .sort(sortDebtorClaims);
};

export const getActiveInvoices = (state: ApplicationState) => {
  return state.debtorInvoices.invoices.filter(x => x.debtCollectionExternalReferenceNumber == null)
    .sort(sortDebtorClaims)
    .filter(x => filterAwayClaimsWithoutNumberOrKid(x) && x.isTopLevelClaim && !x.isClaimToAnotherPayer && (x.status === DebtorClaimStatus.Active || x.status === DebtorClaimStatus.Halted));
};

export const getActiveDebtCollections = (state: ApplicationState) => {
  return state.debtorDebtCollections.debtCollections
    .sort(sortDebtorClaims)
    .filter(x => x.isTopLevelClaim && !x.isClaimToAnotherPayer && (
      x.status === DebtorClaimStatus.Active ||
      x.status === DebtorClaimStatus.Halted ||
      x.status === DebtorClaimStatus.ObjectionReceived ||
      x.status === DebtorClaimStatus.ClosedDueToMinor
    ));
};

export const isDebtorClaimSelectableForConversationSupervisorStep = (conversationSupervisor: ConversationSupervisorState, debtorClaim: DebtorClaim) => {
  const currentStep = steps.find(x => x.id === conversationSupervisor.step);
  if (currentStep === undefined) {
    return true;
  }

  switch (currentStep.group) {
    case StepsGroup.WillPay:
      return debtorClaim.type === 'Invoice' && debtorClaim.amount! > 0 && debtorClaim.status === DebtorClaimStatus.Active;
    case StepsGroup.HasObjection:
      return debtorClaim.type !== 'LeapClaim' && debtorClaim.canRegisterObjection;
    case StepsGroup.Credit:
      return debtorClaim.type === 'LeapClaim' && debtorClaim.canRegisterCreditNote;
    default:
      return true;
  }
};

export const hasSelectedDebtorClaims = (state: DebtorClaimsState) => {
  return state.selectedDebtorClaims.length > 0;
};

export const getSelectedDebtorClaims = (state: DebtorClaimsState) => {
  return state.selectedDebtorClaims;
};

const sortPowerOfAttorneys = (a: AvailablePowerOfAttorney, b: AvailablePowerOfAttorney) => {
  return (a.active === b.active) ? (a.registeredAtUtc > b.registeredAtUtc) ? -1 : 1 : a.active ? -1 : 1;
};

export const getExistingPowerOfAttorneys = (state: ApplicationState) => {
  return state.powerOfAttorney.availablePowerOfAttorneys.sort(sortPowerOfAttorneys);
};