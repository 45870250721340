import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../Layout';

type ClassNames = 'appBar' | 'toolBar';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  appBar: {
    backgroundColor: colors.secondary.springgreen,
  },
  toolBar: {
    minHeight: 0,
    justifyContent: 'center'
  }
});

type ComponentProps = WithStyles<ClassNames>;

const EnvironmentBar: React.FunctionComponent<ComponentProps> = props => {
  const notProduction = !window.location.hostname.startsWith('conveneportal.convenegroup.com');
  
  return (
    <AppBar position="sticky" className={props.classes.appBar} style={{ display: notProduction ? 'block' : 'none' }}>
      <Toolbar variant="dense" className={props.classes.toolBar}>
        <Typography variant="caption" color="inherit">
          Ikke produksjon!
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(EnvironmentBar);
