import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { formatDate } from '../../Utils';
import { ObjectionRegistered } from '../../../api/client';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'content';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  content: {
    whiteSpace: 'pre-line'
  }
});

interface Props {
  item: ObjectionRegistered;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const ObjectionRegisteredDetails: React.FunctionComponent<ComponentProps> = props => (
  <div>
    <Typography><b>Dato:</b> {formatDate(props.item.eventDate)}</Typography>
    <Typography><b>Av:</b> {props.item.registeredBy}</Typography>
    <Typography className={props.classes.content}><b>Årsak: </b>{props.item.reason}</Typography>
  </div>
);

export default withStyles(styles)(ObjectionRegisteredDetails);