import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { DebtorClaim } from '../../../api/client';
import DebtorClaimComponent from '../../debtorOverview/containers/DebtorClaim';

type ClassNames = 'container';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    margin: '0 auto',
    marginBottom: 16,
    paddingBottom: 0
  }
});

interface Props {
  childClaims: DebtorClaim [];
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorClaimChildren: React.FunctionComponent<ComponentProps> = props => (
  <div className={props.classes.container}>
    {props.childClaims && props.childClaims.map((item, i) => <DebtorClaimComponent key={i} debtorClaim={item} isTopLevel={false} />)}
  </div>
);

export default withStyles(styles)(DebtorClaimChildren);