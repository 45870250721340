import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'container';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    paddingTop: 15,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',    
  },
});

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[5],
    fontSize: '0.875rem',    
    fontWeight: 500,
    maxWidth: 600,    
    marginTop: 4
  }
}))(Tooltip);

interface Props {
  creditorName: any;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const CreditorName: React.FunctionComponent<ComponentProps> = props => {  
  return (
    <LightTooltip 
      title={props.creditorName ? props.creditorName : ''}
      enterDelay={600} 
      leaveDelay={300} 
      interactive={true} 
      placement="bottom-start"
      PopperProps={{ 
        style: {opacity: 1},
        popperOptions: {
          modifiers: {
            offset: {
              enabled: true,
              offset: '-8px, 0px',
            },
          },
        },
      }} 
    >
      <Typography className={props.classes.container}>{props.creditorName}</Typography>
    </LightTooltip>
  );
};

export default withStyles(styles)(CreditorName);