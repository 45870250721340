import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { SubmittedConversation } from '../../../store/ConversationSupervisorStore';
import { getSubmittedConversations } from '../../../store/Selectors';
import ContactPhone from '@material-ui/icons/ContactPhone';
import Drawer from '@material-ui/core/Drawer';
import SubmittedConversations from '../../../components/submittedConversations/presentation/SubmittedConversations';

interface PropsFromState {
  submittedConversations: SubmittedConversation[];
}

type ComponentProps = PropsFromState;

export class SubmittedConversationsDrawer extends React.Component<ComponentProps> {
  state = {
    drawerIsOpen: false,
  };

  toggleDrawer = () => {
    this.setState({
      drawerIsOpen: !this.state.drawerIsOpen,
    });
  }

  public render() {
    return (
      <div>
        <Drawer anchor="right" open={this.state.drawerIsOpen} onClose={() => this.toggleDrawer()}>
          <SubmittedConversations submittedConversations={this.props.submittedConversations} />
        </Drawer>
        <ContactPhone onClick={() => this.toggleDrawer()} style={{ width: 48, height: 48 }} color="primary" />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  submittedConversations: getSubmittedConversations(state),
});

export default compose(connect(mapStateToProps))(SubmittedConversationsDrawer);
