import React from 'react';
import Typography from '@material-ui/core/Typography';

interface ComponentProps {
  accountNumber?: string;
  className?: string;
}

function formatAccountNumber(value: string | undefined): string | undefined {
  if (value === undefined || value === '') {
    return value;
  }

  return `${value.slice(0, 4)}.${value.slice(4, 6)}.${value.slice(6)}`;
}

const AccountNumber: React.FunctionComponent<ComponentProps> = props => (
  <Typography className={props.className}>Kontonummer: {formatAccountNumber(props.accountNumber)}</Typography>
);

export default AccountNumber;