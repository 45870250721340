import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { colors } from '../../../Layout';

import invoiceWhite from '../../../assets/images/icons/invoice-white.svg';
import invoiceLost from '../../../assets/images/icons/invoice-lost.svg';
import debtCollectionWhite from '../../../assets/images/icons/debt-collection-white.svg';

const icons = {};
icons['InvoiceActive'] = { tooltip: 'Aktiv', backgroundColor: colors.blue, path: invoiceWhite };
icons['InvoicePaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: invoiceWhite };
icons['InvoiceForgiven'] = { tooltip: 'Ettergitt', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceCredited'] = { tooltip: 'Kreditert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceCancelled'] = { tooltip: 'Kansellert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceBookedAsLost'] = { tooltip: 'Tapsført', backgroundColor: colors.blue, path: invoiceWhite };
icons['InvoiceDebtCollection'] = { tooltip: 'Sendt til inkasso', backgroundColor: colors.red, path: invoiceLost };
icons['InvoiceHalted'] = { tooltip: 'Stanset', backgroundColor: colors.orange, path: invoiceWhite };
icons['InvoiceExpired'] = { tooltip: 'Foreldet', backgroundColor: colors.secondary.zeus, path: invoiceWhite };

icons['LeapClaimActive'] = { tooltip: 'Aktiv', backgroundColor: colors.blue, path: invoiceWhite };
icons['LeapClaimPaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: invoiceWhite };
icons['LeapClaimCredited'] = { tooltip: 'Kreditert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['LeapClaimDebtCollection'] = { tooltip: 'Sendt til inkasso', backgroundColor: colors.red, path: debtCollectionWhite };

icons['LeapDebtCollectionClaimActive'] = { tooltip: 'Aktiv Inkasso', backgroundColor: colors.red, path: invoiceWhite };
icons['LeapDebtCollectionClaimPaid'] = { tooltip: 'Betalt Inkasso', backgroundColor: colors.green, path: invoiceWhite };
icons['LeapDebtCollectionClaimCredited'] = { tooltip: 'Kreditert Inkasso', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['LeapDebtCollectionClaimDebtCollection'] = { tooltip: 'Inkasso', backgroundColor: colors.red, path: debtCollectionWhite };

icons['DebtCollectionActive'] = { tooltip: 'Aktiv', backgroundColor: colors.red, path: debtCollectionWhite };
icons['DebtCollectionPaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: debtCollectionWhite };
icons['DebtCollectionForgiven'] = { tooltip: 'Ettergitt', backgroundColor: colors.darkGray, path: debtCollectionWhite };
icons['DebtCollectionClosedDueToMinor'] = { tooltip: 'Avsluttet mindreårige', backgroundColor: colors.lightRed, path: debtCollectionWhite };
icons['DebtCollectionHalted'] = { tooltip: 'Stanset', backgroundColor: colors.orange, path: debtCollectionWhite };
icons['DebtCollectionObjectionReceived'] = { tooltip: 'Innsigelse', backgroundColor: colors.orange, path: debtCollectionWhite };

type ClassNames = 'container' | 'icon';

const styles = theme => ({
  container: {
    flex: 1,
    padding: '1em',
    maxWidth: 33
  },
  icon: {
    display: 'block'
  },
});

interface Props {
  variant: any;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorClaimIcon: React.FunctionComponent<ComponentProps> = props => {
  const icon = icons[props.variant];

  return (
    <div className={props.classes.container} style={{ backgroundColor: icon.backgroundColor }}>
      <img src={icon.path} alt="icon" title={icon.tooltip} className={props.classes.icon} />
    </div>
  );
};

export default withStyles(styles)(DebtorClaimIcon);