import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import terminalWorkerPage from '../authentication/TerminalWorkerPage';
import Typography from '@material-ui/core/Typography';

type ClassNames = 'text' | 'iframe' | 'iframeinjector';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  text: {
    color: `${theme.palette.secondary.dark}`
  },
  iframeinjector: {
    width: '100%',
    height: '100%'
  },
  iframe: {
    border: 0
  }
});

interface State {
  height: number;
}

type ComponentProps = State & WithStyles<ClassNames>;

class TerminalWorker extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: window.innerHeight
    };
  }

  acquireToken = (event: any) => {
    if (event.data === 'acquireToken') {
      event.source.postMessage(localStorage.getItem('accessToken'), '*');
    }
  }

  updateWindowHeight = () => {
    this.setState({
      height: window.innerHeight
    });
  }

  componentDidMount() {
    window.addEventListener('message', this.acquireToken, false);
    window.addEventListener('resize', this.updateWindowHeight, false);
    console.groupEnd();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.acquireToken, false);
    window.removeEventListener('resize', this.updateWindowHeight, false);
  }

  public render() {
    return (
      <div style={{ height: this.state.height - 110 }}>
        <div className={this.props.classes.iframeinjector}>
          <Typography className={this.props.classes.text}>Terminal page</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(terminalWorkerPage(TerminalWorker));