import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import { DebtorClaimEvent, ClaimEventType, ObjectionRegistered, PaymentInformationSent } from '../../../api/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import ObjectionRegisteredDetails from '../presentation/ObjectionRegisteredDetails';
import PaymentInformationSentDetails from '../presentation/PaymentInformationSentDetails';

interface PropsFromState {
  debtorClaimEventDetails: DebtorClaimEvent;
  isLoading: boolean;
}

interface PropsFromDispatch {
  getDebtorClaimEventDetails: typeof DebtorClaimsStoreActionCreators.getDebtorClaimEventDetails;
}

interface InjectedProps {
  id: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

class DebtorClaimEventDetails extends React.Component<ComponentProps> {
  componentDidMount() {
    this.props.getDebtorClaimEventDetails(this.props.id);
  }

  public render() {
    return (
      <div>
        {this.props.isLoading &&
          <CircularProgress size={50} />
        }
        {this.props.debtorClaimEventDetails && this.props.debtorClaimEventDetails.eventType === ClaimEventType.ObjectionRegistered &&
          <ObjectionRegisteredDetails item={this.props.debtorClaimEventDetails as ObjectionRegistered} />
        }
        {this.props.debtorClaimEventDetails && this.props.debtorClaimEventDetails.eventType &&
         [ClaimEventType.PaymentInformationSentBySms, ClaimEventType.PaymentInformationSentByEmail].indexOf(this.props.debtorClaimEventDetails.eventType) !== -1 &&
          <PaymentInformationSentDetails item={this.props.debtorClaimEventDetails as PaymentInformationSent} />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState, ownProps: ComponentProps): PropsFromState => ({
  debtorClaimEventDetails: debtorClaims.debtorClaimEventDetails[ownProps.id],
  isLoading: debtorClaims.isLoadingDebtorClaimEventDetails,
});

const mapDispatchToProps: PropsFromDispatch = {
  getDebtorClaimEventDetails: DebtorClaimsStoreActionCreators.getDebtorClaimEventDetails,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(DebtorClaimEventDetails);
