import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { Conversation } from '../../../api/client';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import { Step } from './ConversationSupervisorStep';
import ConversationSummaryItem from './ConversationSummaryItem';
import ConversationInterruptedSummaryItem from './ConversationInterruptedSummaryItem';
import Button from '../../../components/common/Button';
import StepZilla from 'react-stepzilla';

type ClassNames = 'list' | 'buttons';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  list: {
    paddingTop: 10,
    minHeight: 370,
  },
  buttons: {
    float: 'right',
  }
});

interface Props {
  conversation: Conversation;
  cancelConversationInterruption: typeof actionCreators.cancelConversationInterruption;
  jumpToStep: typeof StepZilla.jumpToStep;
  removeConversationAction: typeof actionCreators.removeConversationAction;
  submitConversation: typeof actionCreators.submitConversation;
}

type ComponentProps = Props & WithStyles<ClassNames>;

export const CanSubmitConversation = (conversation: Conversation) => {
  return conversation.actions!.length !== 0 || conversation.wasInterrupted;
};

export const ConversationSummaryList: React.FunctionComponent<ComponentProps> = props => (
  <div>
    <div className={props.classes.list}>
      <List dense={true}>
        {props.conversation.actions!.map((item, i) =>
          <ConversationSummaryItem
            key={i}
            index={i}
            item={item}
            conversationWasInterrupted={props.conversation.wasInterrupted}
            removeConversationAction={props.removeConversationAction}
          />)
        }
        {props.conversation.wasInterrupted &&
          <ConversationInterruptedSummaryItem 
            interruptionReason={props.conversation.interruptionReason} 
            cancelConversationInterruption={props.cancelConversationInterruption} 
          />
        }
      </List>
    </div>
    <div className={props.classes.buttons}>
      <Button onClick={() => props.jumpToStep(Step.Start)} disabled={props.conversation.wasInterrupted}>Ny hendelse</Button>
      <Button onClick={() => props.submitConversation()} disabled={!CanSubmitConversation(props.conversation)}>Fullfør samtalen</Button>
    </div>
  </div>
);

export default withStyles(styles)(ConversationSummaryList);