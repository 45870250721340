import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type ClassNames = 'interruptionReason';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  interruptionReason: {
    overflowWrap: 'break-word',
  }
});

export interface Props {
  interruptionReason?: string;
  cancelConversationInterruption: typeof actionCreators.cancelConversationInterruption;
}

type ComponentProps = Props & WithStyles<ClassNames>;

export const handleDelete = (props: Props) => {
  props.cancelConversationInterruption();
};

export const ConversationInterruptedSummaryItem: React.FunctionComponent<ComponentProps> = props => {
  return (
    <ListItem>
      <ListItemIcon>
        <CheckBoxIcon />
      </ListItemIcon>
      <ListItemText primary="Samtale avbrutt" secondary={props.interruptionReason} className={props.classes.interruptionReason} />
      <ListItemSecondaryAction>
        <IconButton aria-label="Slett" onClick={() => handleDelete(props)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(ConversationInterruptedSummaryItem);