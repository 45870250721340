import React from 'react';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import SmsIcon from '@material-ui/icons/TextsmsOutlined';
import SmsReceivedIcon from '@material-ui/icons/PhoneAndroid';
import SmsFailedIcon from '@material-ui/icons/PermDeviceInformation';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';
import UndoIcon from '@material-ui/icons/UndoOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import CreditIcon from '@material-ui/icons/MoneyOffOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import MoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import DoneIcon from '@material-ui/icons/Done';
import PhoneCallIcon from '@material-ui/icons/Call';
import SeeNoteFieldIcon from '@material-ui/icons/VisibilityOutlined';
import FeeAddedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import EmailSentIcon from '@material-ui/icons/AlternateEmail';
import { DigiPostIcon } from '../../../components/CustomIcons';
import { colors } from '../../../Layout';
import { ClaimEventType } from '../../../api/client';

const icons = {
  'PaymentInformationSentByEmail' : { icon: EmailIcon, color: colors.black },
  'PaymentInformationSentBySms': { icon: SmsIcon, color: colors.black },
  'ObjectionRegistered' : { icon: PauseIcon, color: colors.orange },
  'InvoiceReturn' : { icon: UndoIcon, color: colors.black },
  'DebtCollectionWarningReturn' : { icon: UndoIcon, color: colors.black },
  'Lost' : { icon: LockIcon, color: colors.secondary.slategray },
  'Credit' : { icon: CreditIcon, color: colors.secondary.candy },
  'Letter' : { icon: MailIcon, color: colors.black },
  'DebtCollectionWarningLetter' : { icon: MailIcon, color: colors.black },
  'Payment' : { icon: MoneyIcon, color: colors.secondary.bamboo },
  'Remittance' : { icon: MoneyIcon, color: colors.green },
  'InvoiceFullyPaid' : { icon: DoneIcon, color: colors.green },
  'InvoiceSmsReceived' : { icon: SmsReceivedIcon, color: colors.green },
  'InvoiceSmsNotReceived' : { icon: SmsFailedIcon, color: colors.secondary.candy },
  'MobilePaySmsReceived' : { icon: SmsReceivedIcon, color: colors.green },
  'MobilePaySmsNotReceived' : { icon: SmsFailedIcon, color: colors.secondary.candy },
  'SmsSent' : { icon: SmsReceivedIcon, color: colors.black },
  'PhoneCall' : { icon: PhoneCallIcon, color: colors.black },
  'SeeNoteField' : { icon: SeeNoteFieldIcon, color: colors.black },
  'FeeAdded' : { icon: FeeAddedIcon, color: colors.black },
  'DigiPostInvoice' : { icon: DigiPostIcon, color: colors.black },
  'DigiPostDebtCollectionWarning' : { icon: DigiPostIcon, color: colors.black },
  'Email' : { icon: EmailSentIcon, color: colors.black }
};

interface ComponentProps {
  eventType: ClaimEventType;
}

const DebtorClaimEventIcon: React.FunctionComponent<ComponentProps> = props => {
  const Icon = icons[ClaimEventType[props.eventType]] === undefined ? null : icons[ClaimEventType[props.eventType]].icon;
  const Color = icons[ClaimEventType[props.eventType]] === undefined ? null : icons[ClaimEventType[props.eventType]].color;
  return (
    Icon && <Icon style={{color: Color}} />
  );
};

export default DebtorClaimEventIcon;