import React from 'react';
import defaultPage from '../../components/authentication/DefaultPage';
import { Redirect } from 'react-router-dom';

interface ComponentProps {
  isAuthenticated: boolean;
}

const securePageHoc = Page => class SecurePage extends React.Component<ComponentProps> {
  render() {
    if (!this.props.isAuthenticated) {
      // Until state parameter is available we use local storage https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/262
      localStorage.setItem('authorization.redirectTo', window.location.search === '' ? window.location.pathname : window.location.pathname + window.location.search);
      return <Redirect to="/login" />;
    }

    return <Page {...this.props} />;
  }
};

const securePage = Page => defaultPage(securePageHoc(Page));

export default securePage;
