import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { colors } from '../../../Layout';

const systemInformation = {};
systemInformation['Invoice'] = { systemLetter: 'MMSYS', backgroundColor: colors.secondary.bluelagoon, tooltip: 'MMSYS' };
systemInformation['LeapClaim'] = { systemLetter: 'LEAP', backgroundColor: colors.primary.bondiblue, tooltip: 'Leap' };
systemInformation['LeapDebtCollectionClaim'] = { systemLetter: 'LEAP', backgroundColor: colors.primary.bondiblue, tooltip: 'Leap' };
systemInformation['DebtCollection'] = { systemLetter: 'PRED', backgroundColor: colors.red, tooltip: 'Predator' };

type ClassNames = 'container';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    float: 'left',
    borderRadius: 2,
    minWidth: 52,
    padding: '1px 0px',
    textAlign: 'center',
    marginRight: 5,
    marginTop: 1,
    marginBottom: -1,
    color: colors.white,
    fontFamily: 'Open Sans,sans-serif',
    fontSize: 12,
    fontWeight: 700,
  },
});

interface Props {
  system: any;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorClaimSystemBadge: React.FunctionComponent<ComponentProps> = props => {
  const systemInfo = systemInformation[props.system];
  return (systemInfo && systemInfo.backgroundColor && systemInfo.tooltip) ? (
    <div className={props.classes.container} style={{ backgroundColor: systemInfo.backgroundColor }} title={systemInfo.tooltip}>
      <span>{systemInfo.systemLetter}</span>
    </div>
  ) : null;
};

export default withStyles(styles)(DebtorClaimSystemBadge);