import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import { create } from 'jss';
import createPalette from '@material-ui/core/styles/createPalette';
import ApplicationBar from './components/applicationBar/ApplicationBar';
import EnvironmentBar from './components/environment/EnvironmentBar';
import * as NotificationsStore from './store/NotificationsStore';
import NotificationsSnackbar from './components/notifications/NotificationsSnackbar';
import JssProvider from 'react-jss/lib/JssProvider';
import preset from 'jss-preset-default';
import NewVersionAvailableBar from './components/applicationVersion/NewVersionAvailableBar';
import { getStartingPageForLoginWithRoles } from './components/Utils';

export const colors = {
  grape: '#502882',
  gray: '#585858',
  darkGray: '#ABABAB',
  shadow: '#D5D5D5',
  marble: '#ECF1F4',
  lightGrape: '#EEEAF3',
  lightPurple: '#EFEBF3',
  lightGray: '#FAFAFA',
  white: '#FFFFFF',
  raspberry: '#B4148C',
  red: '#E20052',
  darkred: '#CD001A',
  green: '#00C24F',
  blue: '#3737ff',
  lightRed: '#E57373',
  smoke: '#A394A9',
  black: '#000000',
  amber: '#FFD100',
  orange: '#FF9800',
  primary: {
    bondiblue: '#00B1B1',
    bluezodiac: '#163556',
    milkyway: '#E4F3F3',
  },
  secondary: {
    jaggedice: '#B8E6E6',
    slategray: '#708090',
    bamboo: '#6CBA86',
    salmon: '#FF9F7A',
    springgreen: '#28FFAB',
    bluelagoon: '#005E69',
    zeus: '#3C3C3C',
    candy: '#DC5A6E',
    lemon: '#FFD31B',
  }
};

const customTheme = createMuiTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    useNextVariants: true,
  },
  palette: createPalette({
    type: 'light',
    primary: {
      main: colors.primary.bondiblue,
      dark: colors.primary.bluezodiac,
      light: colors.primary.milkyway,
    },
    secondary: {
      main: colors.white,
      dark: colors.black
    },
    error: {
      main: colors.secondary.candy
    },
    background: {
      default: colors.white
    }
  })
});

interface PropsFromState {
  isAuthenticated: boolean;
  userProfile?: string;
  roles: string[];
  notifications: NotificationsStore.Notification[];
  newApplicationVersionIsAvailable: boolean;
}

type ComponentProps = PropsFromState & { children?: React.ReactNode };

class LayoutComponent extends React.Component<ComponentProps> {
  render() {
    const jss = create(preset());
    return (
      <JssProvider jss={jss}>
        <MuiThemeProvider theme={customTheme}>
          <ApplicationBar userProfile={this.props.userProfile} defaultPath={getStartingPageForLoginWithRoles(this.props.roles)} />
          <div>
            <EnvironmentBar />
            {this.props.newApplicationVersionIsAvailable === true ? <NewVersionAvailableBar /> : null}
            {this.props.children}
          </div>
          <NotificationsSnackbar />
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

const mapStateToProps = ({ authentication, notifications, applicationVersion }: ApplicationState): PropsFromState => ({
  isAuthenticated: authentication.isAuthenticated,
  userProfile: authentication.userProfile,
  roles: authentication.roles,
  notifications: notifications.messages,
  newApplicationVersionIsAvailable: applicationVersion.newVersionAvailable
});

export default connect(mapStateToProps)(LayoutComponent);