import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import { formatDate, formatTime } from '../../Utils';
import { DebtorClaimEvent } from '../../../api/client';
import currencyFormatter from 'currency-formatter';
import DebtorClaimEventDetailsTooltip from '../containers/DebtorClaimEventDetailsTooltip';

type ClassNames = 'container' | 'debtorClaimEventDetails' | 'eventDate' | 'description' | 'amount';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 15,
  },
  debtorClaimEventDetails: {
    minWidth: 40
  },
  eventDate: {
    marginRight: 30,
  },
  description: {
    flexGrow: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  amount: {
    fontWeight: 'bold',
    marginLeft: 10
  }
});

interface Props {
  event: DebtorClaimEvent;
  debtorId: String;
}

type ComponentProps = Props & WithStyles<ClassNames>;

const DebtorClaimEventComponent: React.FunctionComponent<ComponentProps> = props => (
  <div>
    <div className={props.classes.container}>
      <Tooltip title={formatTime(props.event.eventDate)} placement="left-start">
        <Typography className={props.classes.eventDate}>{formatDate(props.event.eventDate)}</Typography>
      </Tooltip>
      <div className={props.classes.debtorClaimEventDetails}>
          <DebtorClaimEventDetailsTooltip debtorId={props.debtorId!} eventId={props.event.eventId!} eventType={props.event.eventType!} eventTooltip={props.event.tooltip!}/>
      </div>
      <div className={props.classes.description}>
        <Typography>{props.event.description}</Typography>
      </div>
      <Typography className={props.classes.amount}>{props.event.amount ? currencyFormatter.format(props.event.amount, { code: 'NOK' }) : ''}</Typography>
    </div>
    <Divider />
  </div>
);

export default withStyles(styles)(DebtorClaimEventComponent);