import React from 'react';
import { withStyles, WithStyles, StyleRulesCallback } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@material-ui/icons/ArrowRight';
import PreviousStep from './PreviousStep';

type ClassNames = 'listItem';

const styles: StyleRulesCallback<ClassNames> = theme => ({
  listItem: {
    paddingRight: 0,
  }
});

interface Props {
  hasSelectedDebtorClaims: boolean;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = Props & InjectedProps & WithStyles<ClassNames>;

const PaymentInformation: React.FunctionComponent<ComponentProps> = props => (
  <div>
    <PreviousStep previousStep={() => props.jumpToStep(Step.PaymentAgreement)} />
    <List>
      <ListItem className={props.classes.listItem} button={true} disabled={!props.hasSelectedDebtorClaims} onClick={() => props.jumpToStep(Step.SendPaymentInformation)}>
        <ListItemText primary="Send betalingsinformasjon" />
        <ArrowRight />
      </ListItem>
    </List>
  </div>
);

export default withStyles(styles)(PaymentInformation);